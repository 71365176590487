import { FormInstance } from 'antd';
import { isNil } from 'lodash';
import { REGEX_ONLY_NUMBER, messageError } from 'common/const';
import { Rule } from 'antd/lib/form';
import { dateUtils } from 'common/dateUtils';
import { Moment } from 'moment';
import { getValueInputSymbol } from 'helper';

interface IValidator {
  getFieldValue: FormInstance['getFieldValue'];
}

export const useRulesForm = () => {
  const rulesForm = (nameField = '', message?: string) => ({
    onlyNumber: { pattern: REGEX_ONLY_NUMBER, message: '数字を入力してください' },
    required: {
      required: true,
      message: message || messageError.requiredField,
    },
    requiredWithTrim: {
      required: true,
      message: message || messageError.requiredField,
      whitespace: true,
    },
    dynamicRequired: (required?: boolean) => ({
      required: Boolean(required),
      message: message || messageError.requiredField,
    }),
    checkPositiveNumber:
      () =>
      ({ getFieldValue }: IValidator) => ({
        validator(_rule: any, value: string) {
          if (value && +value < 0) {
            return Promise.reject(message || '必要人は0人以上です');
          }

          return Promise.resolve();
        },
      }),
    checkPositiveNumberBiggerThanZero:
      ({ isHavePrefix, isHaveSuffix }: { isHavePrefix?: boolean; isHaveSuffix?: boolean }) =>
      ({ getFieldValue }: IValidator) => ({
        validator(_rule: any, value: string) {
          let valueInput = value;

          if (isHavePrefix) {
            valueInput = getValueInputSymbol(value);
          }

          if (isHaveSuffix) {
            valueInput = getValueInputSymbol(value, false);
          }

          if (+valueInput <= 0) {
            return Promise.reject(message || '0より大きい数を入力してください。');
          }

          return Promise.resolve();
        },
      }),
    checkMaxNumber:
      (maxNumber?: number) =>
      ({ getFieldValue }: IValidator) => ({
        validator(_rule: any, value: string) {
          if (!isNil(value) && !isNil(maxNumber) && +value > +maxNumber) {
            return Promise.reject(message || '必要人数以内で入力してください。');
          }

          return Promise.resolve();
        },
      }),
    registrationNumber: [
      {
        validator(_rule: Rule, value: string) {
          if (value === undefined || value === '' || value === null || REGEX_ONLY_NUMBER.test(value)) {
            return Promise.resolve();
          }

          return Promise.reject('数字のみを入力してください。');
        },
      },
      {
        len: 13,
        message: '13桁の数字を入力してください。',
      },
    ],
    endDate: [
      {
        required: true,
        message: messageError.requiredField,
      },
      ({ getFieldValue }: IValidator) => ({
        validator(_: any, value: string) {
          if (!value || getFieldValue('startDate')?.isSameOrBefore(value)) {
            return Promise.resolve();
          }
          return Promise.reject(new Error('入力した時間が正しくありません。'));
        },
      }),
    ],
    validateTwoDate:
      (nameStartDate: string, endStartDate: string) =>
      ({ getFieldValue }: IValidator) => ({
        validator(_: any, endDate: any) {
          const startDate = getFieldValue(nameStartDate);

          const isStartDateBeforeEndDate = dateUtils.isSameOrBeforeDate(startDate, endDate);

          if (!isStartDateBeforeEndDate) {
            return Promise.reject('入力した時間が正しくありません。');
          }

          return Promise.resolve();
        },
      }),
    validateSameDateAndStartBeforeEnd:
      (nameStartDate: string, endStartDate: string) =>
      ({ getFieldValue }: IValidator) => ({
        validator(_: any, endDate: any) {
          const startDate = getFieldValue(nameStartDate);

          const isStartDateBeforeEndDate = dateUtils.isSameOrBeforeDate(startDate, endDate);
          const isSameDate = dateUtils.isSameDate(startDate, endDate);

          if (!isStartDateBeforeEndDate || isSameDate) {
            return Promise.reject('入力した時間が正しくありません。');
          }

          return Promise.resolve();
        },
      }),
    // validate if date is in same month and year.
    validateDateSameMonthAndYear:
      (date?: Moment | null) =>
      ({ getFieldValue }: IValidator) => ({
        validator(_rule: any, value: any) {
          const dateVal = date ?? getFieldValue(['date']);
          const isSameMonth = value.isSame(dateVal, 'month');
          const isSameYear = value.isSame(dateVal, 'year');

          if (isSameMonth && isSameYear) {
            return Promise.resolve();
          }

          return Promise.reject('登録月と他の日が異なっています。再確認してください。');
        },
      }),
    // validate if date is overlap in list exceptData
    validateOverlapDate:
      () =>
      ({ getFieldValue }: IValidator) => ({
        validator(rule: any, value: Moment) {
          const currentIndex = (rule?.fullField as string)?.split('.')?.[1];

          const otherDates = getFieldValue('exceptData')?.map((item: any) => item?.date) as Moment[];

          const firstIndex = otherDates?.findIndex((date, index) => date?.isSame(value));

          if (firstIndex === +currentIndex) {
            return Promise.resolve();
          }

          return Promise.reject(messageError.overlapDate);
        },
      }),
    // check if the date is overlap in list dayOffs
    validateOverlapDateDayOffs:
      () =>
      ({ getFieldValue }: IValidator) => ({
        validator(rule: any, value: Moment) {
          const currentIndex = (rule?.fullField as string)?.split('.')?.[1];

          const otherDates = getFieldValue('dayOffs')?.map((item: any) => item?.format) as Moment[];

          const firstIndex = otherDates?.findIndex((date, index) => date?.isSame(value, 'day'));

          if (firstIndex === +currentIndex) {
            return Promise.resolve();
          }

          return Promise.reject(messageError.overlapDate);
        },
      }),
    // validate if at least one checkbox shift is checked
    validateCheckBoxShiftTask:
      () =>
      ({ getFieldValue }: IValidator) => ({
        validator(rule: any, value: Moment) {
          const morningShift = getFieldValue('morningShift');
          const afternoonShift = getFieldValue('afternoonShift');
          const eveningShift = getFieldValue('eveningShift');
          const otherShift = getFieldValue('otherShift');

          if (morningShift || afternoonShift || eveningShift || otherShift) {
            return Promise.resolve();
          }
          return Promise.reject('入力した時間が正しくありません。');
        },
      }),

    // validate if at least one checkbox shift is checked
    validateCheckBoxShiftTaskV2:
      () =>
      ({ getFieldValue }: IValidator) => ({
        validator(rule: any, value: Moment) {
          const morningShift = getFieldValue('morningShiftCustomer');
          const afternoonShift = getFieldValue('afternoonShiftCustomer');
          const eveningShift = getFieldValue('eveningShiftCustomer');
          const otherShift = getFieldValue('otherShiftCustomer');

          if (morningShift || afternoonShift || eveningShift || otherShift) {
            return Promise.resolve();
          }
          return Promise.reject('入力した時間が正しくありません。');
        },
      }),

    // validate if date is between start and end date
    validateDateBetweenStartAndEndDate:
      (startDateName: string, endDateName: string) =>
      ({ getFieldValue }: IValidator) => ({
        validator(rule: any, value: Moment) {
          const startDate = getFieldValue(startDateName);
          const endDate = getFieldValue(endDateName);

          if (value.isBetween(startDate, endDate, 'day', '[]')) {
            return Promise.resolve();
          }

          return Promise.reject(messageError.timeNotBetweenTwoDate);
        },
      }),
    validateNumberDecimal:
      (decimal: number) =>
      ({ getFieldValue }: IValidator) => ({
        validator(rule: any, value: string) {
          const decimalPattern = new RegExp(`^-?\\d+(\\.\\d{1,${decimal}})?$`);
          if (!value || decimalPattern.test(value)) {
            return Promise.resolve();
          }

          return Promise.reject(new Error(`小数点以下${decimal}桁まで入力してください。`));
        },
      }),
  });

  return { rulesForm };
};
