import { Col, Form, FormInstance } from 'antd';
import { ICertificate, TTypeShift } from 'common/interface';
import FormAddCertificate from 'components/FormAddCertificate';
import styles from './styles.module.scss';
import { CommonInputNumber } from 'components/CommonInputNumber';
import { useRulesForm } from 'hooks/useRulesForm';
import { UserRole } from 'common';
import classNames from 'classnames';
import { capitalize } from 'lodash';
import { roundNumberWorkerTask } from 'helper';

interface IMaxWorkerShiftProps {
  typeShift: TTypeShift;
  form: FormInstance;
  currentRoleInCompany?: UserRole;
  selectedConstructionSiteId?: number;
  certificateOptions?: ICertificate[];
  labelMaxWorker?: string;
  disabledMaxWorker?: boolean;
  disableAddCertificate?: boolean;
  hideMaxWorker?: boolean;
  nameCheckBox?: string;
}

const MaxWorkerShift = ({
  typeShift,
  form,
  currentRoleInCompany,
  selectedConstructionSiteId,
  certificateOptions = [],
  labelMaxWorker,
  disabledMaxWorker,
  disableAddCertificate,
  hideMaxWorker = false,
  nameCheckBox = `${typeShift}Shift`,
}: IMaxWorkerShiftProps) => {
  const { rulesForm } = useRulesForm();

  const isShowAddCertificate = currentRoleInCompany !== UserRole.LEADER;

  // name of field maxWorker
  const nameMaxWorker = `maxWorker${capitalize(typeShift)}`;

  return (
    <Form.Item noStyle shouldUpdate>
      {({ getFieldValue }) => {
        const isCheckedShift = Boolean(getFieldValue(nameCheckBox));

        return (
          isCheckedShift && (
            <>
              {hideMaxWorker && (
                <Col span={24}>
                  <div className="text-error">{labelMaxWorker}</div>
                </Col>
              )}

              {!hideMaxWorker && (
                <Col span={8}>
                  <Form.Item
                    label={labelMaxWorker}
                    name={nameMaxWorker}
                    className={classNames(styles.colorRed, 'mb-0')}
                    rules={[
                      rulesForm().required,
                      rulesForm().checkPositiveNumber(),
                      rulesForm().validateNumberDecimal(2),
                    ]}
                    validateFirst
                  >
                    <CommonInputNumber
                      min={0}
                      placeholder={labelMaxWorker}
                      onChange={(value) => {
                        if (+value < 0) return;

                        const maxWorkerRound = roundNumberWorkerTask(+value);

                        form.setFieldsValue({ [`worker${capitalize(typeShift)}`]: maxWorkerRound });
                      }}
                      disabled={disabledMaxWorker}
                    />
                  </Form.Item>
                </Col>
              )}

              {isShowAddCertificate && (
                <Col
                  span={form.getFieldValue(typeShift)?.length > 0 ? 24 : 16}
                  className={classNames({
                    [styles.formAddCertificateCol]: form.getFieldValue(typeShift)?.length > 0,
                  })}
                >
                  <FormAddCertificate
                    typeShift={typeShift}
                    showIcons
                    options={certificateOptions}
                    maxWorker={form.getFieldValue(nameMaxWorker)}
                    form={form}
                    dependenciesMaxWorker={[nameMaxWorker]}
                    key={`${typeShift}_${selectedConstructionSiteId}`}
                    disabledAll={disableAddCertificate}
                    stylesTextAdd={{ marginTop: hideMaxWorker ? '0' : '30px' }}
                  />
                </Col>
              )}
            </>
          )
        );
      }}
    </Form.Item>
  );
};

export default MaxWorkerShift;
