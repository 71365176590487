import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Dropdown,
  Form,
  Menu,
  ModalProps,
  Row,
  Space,
  Typography,
} from 'antd';
import Text from 'antd/lib/typography/Text';
import { getConstructionSiteTaskDetails, updateReserveTask, updateTaskV2 } from 'api/constructionSite';
import icEditWhite from 'assets/icons/edit-3-white.svg';
import icEdit from 'assets/icons/edit-3.svg';
import icTrash from 'assets/icons/trash-2.svg';
import icCheck from 'assets/icons/check-circle.svg';
import { ShipOfDay, STATUS, WORKING_TIME, UpdateTaskMode, UserRole, ModeType } from 'common';
import icSetting from 'assets/icons/setting.svg';
import icForward from 'assets/icons/forward.svg';
import icPaper from 'assets/icons/paper.svg';
import { dateUtils } from 'common/dateUtils';
import { CommonButton } from 'components/CommonButton';

import { checkTaskSeparatedShift, confirmPopup, handleErrorMessage, handleMessageSuccess } from 'helper';
import useProfile from 'hooks/useProfile';
import { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { UpdateTaskModal } from '../UpdateTask';
import styles from './styles.module.scss';
import { ITaskDetail, ITask, ICheckedTask, IWorker } from 'common/interface';
import { ModalConfirmCustomerUpdate } from './ModalConfirmCustomerUpdate';
import moment from 'moment';
import StaffItem from '../components/StaffItem';
import VenderItem from '../components/VenderItem';
import ModalInforDetailsEdit from '../components/ModalInforDetailsEdit';
import useToggleModal from 'hooks/useToggleModal';
import Loader from 'components/Loader';
import CertificateCustomer from './CertificateCustomer';
import ItemVendorPicker from '../VendorPicker/ItemVendorPicker';
import { useForm } from 'antd/lib/form/Form';
import { ErrorCode, ROLES_ADMIN, ROLES_WORKER_TASK } from 'common/const';
import MetaDataCertificateCustomer from './MetaDataCertificateCustomer';
import useViewTask from 'hooks/useViewTask';
import icUserBlack from 'assets/icons/single-user-black.svg';
import icCalendarWhite from 'assets/icons/calendar-white-2.svg';
import icAddFee from 'assets/icons/add-fee.svg';

import icCompany from 'assets/icons/company.svg';
import icDoubleUserBlack from 'assets/icons/user-double-black.svg';
import { CommonModal } from 'components/CommonModal';
import classNames from 'classnames';
import SectionWrapper from 'components/SectionWrapper';
import icNoteTask from 'assets/icons/note-task.svg';
import ButtonSendEmail from '../components/ButtonSendEmail';
import useGetWidth from '../hooks/useGetWidth';
import DetailsTimeDesire from '../components/DetailsTimeDesire';
import DetailsListDayOffs from './components/DetailsListDayOffs';
import useIsAppearMetadata from './hooks/useIsAppearMetadata';
import MetadataTimeDesire from './components/MetadataTimeDesire';
import MetadataListDayOffs from './components/MetadataListDayOffs';
import NameCompanyWithColor from 'pages/Calendar/components/NameCompanyWithColor';
import AccessControlMultiCompany from 'components/AccessControlMultiCompany';
import useGetRoleInCompany from '../hooks/useGetRoleInCompany';
import useProfileCompanySuper from 'hooks/apis/useProfileCompanySuper';
import AddressProject from 'pages/Calendar/components/AddressProject';
import DetailsTime from './components/DetailsTime';
import LabelDetailsTime from './components/LabelDetailsTime';
import { getNumberVendorHaveCertificate } from './utils/getNumberVendorHaveCertificate';
import MetadataTime from './components/MetadataTime';
import { useDragWorkersStore } from 'stores/DragWorkersStore';
import { useListDeleteTaskStore } from 'stores/ListDeleteTaskStore';
import { AxiosError } from 'axios';
import CommonSpace from 'components/CommonSpace';

interface IPropsModal extends ModalProps {
  visible?: boolean;
  onCancel: () => any;
  taskId: number;
  task?: ITask;
  setFilterCalendar?: any;
}

const spanColLabel = 8;
const spanColValue = 16;
export const emptyData = '-';

export const TaskDetailModal = forwardRef<any, IPropsModal>((props, ref) => {
  const { visible = true, taskId, onCancel, task, setFilterCalendar, className } = props;

  const profile = useProfile();
  const queryClient = useQueryClient();

  const [showEditTaskModal, setShowEditTaskModal] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [modeUpdate, setModeUpdate] = useState<any>();

  const [form] = useForm();

  const { isOpen: openModalDetailsEdit, toggleModal } = useToggleModal(false);

  const handleEdit = (mode: number) => {
    setShowEditTaskModal(true);
    setModeUpdate(mode);
  };

  const menuReserve = (
    <Menu>
      <Menu.Item onClick={() => handleReserve(UpdateTaskMode.ONE)}>
        <div className={styles.listItem}>
          <img src={icSetting} alt="" />
          この手配のみ
        </div>
      </Menu.Item>
      <Menu.Item onClick={() => handleReserve(UpdateTaskMode.FUTURE)}>
        <div className={styles.listItem}>
          <img src={icSetting} alt="" />
          この手配の今後
        </div>
      </Menu.Item>
      <Menu.Item onClick={() => handleReserve(UpdateTaskMode.ALL)}>
        <div className={styles.listItem}>
          <img src={icSetting} alt="" />
          この手配系の全て
        </div>
      </Menu.Item>
    </Menu>
  );

  const handleReserve = async (mode: number) => {
    confirmPopup({
      title: 'この手配を保留しますか。',
      onOk: async () => {
        try {
          await updateReserveTask(Number(taskId), { reserveStatus: STATUS.ACTIVE, mode: mode });
          queryClient.invalidateQueries(['construction-site-detail']);
          queryClient.invalidateQueries(['list-tasks--calendar']);
          queryClient.invalidateQueries(['list-tasks--home-calendar']);
          queryClient.invalidateQueries(['list-tasks-of-construction']);
          queryClient.invalidateQueries(['construction-site-task-detail']);
          onCancel();
        } catch (e) {
          handleErrorMessage(e);
        }
      },
    });
  };

  const handleRemoveReserve = async () => {
    confirmPopup({
      title: 'この手配を戻しますか。',
      onOk: async () => {
        try {
          await updateReserveTask(Number(taskId), { reserveStatus: STATUS.INACTIVE });
          queryClient.invalidateQueries(['construction-site-detail']);
          queryClient.invalidateQueries(['list-tasks--calendar']);
          queryClient.invalidateQueries(['list-tasks--home-calendar']);
          queryClient.invalidateQueries(['list-tasks-of-construction']);
          queryClient.invalidateQueries(['construction-site-task-detail']);
          onCancel();
        } catch (e) {
          handleErrorMessage(e);
        }
      },
    });
  };

  const [isTaskDeleted, setTaskDeleted] = useState(false);

  // get details task
  const { data: taskDetail, isLoading: isLoadingTaskDetail }: { data?: ITaskDetail; isLoading: boolean } = useQuery(
    ['construction-site-task-detail', taskId],
    () => getConstructionSiteTaskDetails(taskId).then((res: any) => res?.data),
    {
      enabled: !!taskId,
      onSuccess: (data) => {},
      onError(err: AxiosError) {
        const errorData = err?.response ? err?.response?.data : null;

        if (errorData?.errorCode === ErrorCode.Task_Deleted) {
          // show message error
          setTaskDeleted(true);
        }

        handleErrorMessage(err);
      },
    }
  );

  const { currentRoleInCompany, companySelected, isVendorCustomerOfTask, isVendorOfTask, isCustomerOfTask } =
    useGetRoleInCompany({
      taskDetail,
      mode: ModeType.VIEW,
    });

  // get profile of current company
  const { profileCompanySuper: profileCompany } = useProfileCompanySuper({
    companyId: companySelected?.id,
    enabled: true,
  });

  const menu = (
    <Menu>
      <Menu.Item onClick={() => handleEdit(UpdateTaskMode.ONE)}>
        <div className={styles.listItem}>
          <img src={icEdit} alt="" />
          この手配のみ
        </div>
      </Menu.Item>

      <Menu.Item onClick={() => handleEdit(UpdateTaskMode.FUTURE)}>
        <div className={styles.listItem}>
          <img src={icEdit} alt="" />
          この手配の今後
        </div>
      </Menu.Item>
      <Menu.Item onClick={() => handleEdit(UpdateTaskMode.ALL)}>
        <div className={styles.listItem}>
          <img src={icEdit} alt="" />
          この手配系の全て
        </div>
      </Menu.Item>
    </Menu>
  );

  const menuDelete = (
    <Menu>
      <Menu.Item onClick={() => handleDelete(UpdateTaskMode.ONE)}>
        <div className={styles.listItem}>
          <img src={icEdit} alt="" />
          この手配のみ
        </div>
      </Menu.Item>
      <Menu.Item onClick={() => handleDelete(UpdateTaskMode.FUTURE)}>
        <div className={styles.listItem}>
          <img src={icEdit} alt="" />
          この手配の今後
        </div>
      </Menu.Item>
      <Menu.Item onClick={() => handleDelete(UpdateTaskMode.ALL)}>
        <div className={styles.listItem}>
          <img src={icEdit} alt="" />
          この手配系の全て
        </div>
      </Menu.Item>
    </Menu>
  );

  const { removeSingleTempTask } = useDragWorkersStore();
  const { resetSelectedDeleteTask } = useListDeleteTaskStore();

  const handleDelete = async (mode: number) => {
    confirmPopup({
      title: 'この手配を削除しますか。',
      onOk: async () => {
        try {
          await updateTaskV2({
            id: +taskId,
            payload: { status: STATUS.INACTIVE, mode: mode },
            companyId: companySelected?.id,
          });

          /* ------------ Remove temporary worker and selected delete task ------------ */
          removeSingleTempTask({ taskId: taskId as number });
          resetSelectedDeleteTask();

          queryClient.invalidateQueries(['construction-site-detail']);
          queryClient.invalidateQueries(['list-tasks--calendar']);
          queryClient.invalidateQueries(['list-tasks--home-calendar']);
          queryClient.invalidateQueries(['list-tasks-of-construction']);
          // queryClient.invalidateQueries(['construction-site-task-detail']); --> remove this to fix bug reload data
          // queryClient.invalidateQueries(['construction-site-task-detail']); --> remove this to fix bug reload data

          handleMessageSuccess();

          onCancel();
        } catch (e) {
          handleErrorMessage(e);
        }
      },
    });
  };

  const vendors = taskDetail?.vendors || [];
  const workers = taskDetail?.workers || [];

  const isAdmin = [UserRole.ADMIN, UserRole.MANAGER, UserRole.LEADER, UserRole.OFFICE_MANAGER].includes(
    currentRoleInCompany
  );

  const isCanViewMetadata = ROLES_ADMIN.includes(currentRoleInCompany) || isCustomerOfTask;

  const { isAppearMetadata, metadataCustomerUpdate } = useIsAppearMetadata({ taskDetail });

  const isDifferentFile = useMemo(() => {
    const result = metadataCustomerUpdate?.files?.filter(
      (el1: any) => !taskDetail?.files?.some((el2) => el1?.name === el2?.name)
    );

    if (result?.length) return true;
    return false;
  }, [metadataCustomerUpdate, taskDetail]);

  const renderBtn = () => {
    // const isOnlyVendor = isVendorInTask && !isCustomerInTask;
    // const isOnlyCustomer = !isVendorInTask && isCustomerInTask;

    const isShowOneOption = isVendorCustomerOfTask;

    // Only Vendor => Show 3
    // Vendor customer =>
    //   - Role trong task là cả 2 roles ? Tạm thời show 1, mặc định là update all
    //   - Role trong task chỉ là Vendor thôi => show 3
    //   - Role trong task chỉ là Customer => Show 3.
    // Only Role customer => show 3

    if (isShowOneOption) {
      return (
        <Button
          type="primary"
          className={styles.icons}
          icon={<img src={icEditWhite} alt="" />}
          onClick={() => handleEdit(UpdateTaskMode.ALL)}
        >
          {`編集 `}
        </Button>
      );
    }

    const isSeriesSameTime = taskDetail?.seriesTo === taskDetail?.seriesFrom;

    if (isSeriesSameTime) {
      return (
        <Button
          type="primary"
          className={styles.icons}
          icon={<img src={icEditWhite} alt="" />}
          onClick={() => handleEdit(UpdateTaskMode.ONE)}
        >
          {`編集 `}
        </Button>
      );
    }

    return (
      <Dropdown
        overlay={menu}
        getPopupContainer={(triggerNode: any) => triggerNode.parentElement}
        placement="bottomLeft"
        trigger={['click']}
        arrow
      >
        <Button type="primary" className={styles.icons} icon={<img src={icEditWhite} alt="" />}>
          {`編集 `}
        </Button>
      </Dropdown>
    );
  };

  // set value for certificate of vendor
  useEffect(() => {
    if (!taskDetail?.vendors) return;

    // example certificatesVendor :
    // const certificatesVendor = {
    //   459: [
    //     { workers: 2, certificateId: { value: 175, label: 'JLPT' } },
    //     { workers: 1, certificateId: { value: 176, label: 'Toeic' } },
    //   ],
    // };

    const certificatesVendor = taskDetail.vendors?.reduce((result, vendor) => {
      const { id } = vendor;

      const itemCertificateVendor = vendor?.metadataUnitPriceCertificate?.map((certificate) => ({
        workers: certificate.workers,
        certificateId: { value: certificate.id, label: certificate.title },
      }));

      return { ...result, [`certificate_vendor_id_${id}`]: itemCertificateVendor };
    }, {} as any);

    form.setFieldsValue({ ...certificatesVendor });
  }, [form, taskDetail]);

  const vendor = useMemo(() => {
    return {
      vendorMorning: taskDetail?.vendors?.filter((x: any) => x?.morningShift === 1),
      vendorAfternoon: taskDetail?.vendors?.filter((x: any) => x?.afternoonShift === 1),
      vendorEvening: taskDetail?.vendors?.filter((x: any) => x?.eveningShift === 1),
      vendorOther: taskDetail?.vendors?.filter((x: any) => x?.otherShift === 1),
    };
  }, [taskDetail]);

  const {
    isCanViewTime,
    isCanViewOtherField,
    isCanViewMaxWorker,
    isCanViewCertificateCustomer,
    isCanViewMorningShift,
    isCanViewAfternoonShift,
    isCanViewEveningShift,
    isCanViewOtherShift,
    isCanViewShifts,
  } = useViewTask({
    vendor,
    taskDetail,
  });

  const checked: ICheckedTask = {
    morning: taskDetail?.morningShift,
    afternoon: taskDetail?.afternoonShift,
    evening: taskDetail?.eveningShift,
    other: taskDetail?.otherShift,
  };

  const { spanCol1, spanCol2, widthModal } = useGetWidth({
    checked,
    mode: ModeType.VIEW,
    task: taskDetail,
    companySelected,
  });

  const detailsTaskRef = useRef<HTMLDivElement>(null);

  const disableLinkVendor = currentRoleInCompany === UserRole.LEADER;

  const isCanViewLinkWorker = (worker: IWorker) => {
    // admin, manager, leader, office manager
    if (isAdmin) return true;

    // worker -> all roles workers cant view other people
    // only view myself
    if (ROLES_WORKER_TASK.includes(currentRoleInCompany)) {
      return worker?.userId === profile?.id;
    }

    // vendor | vendor_customer
    if (isVendorOfTask) {
      return profile?.id === worker?.managerId;
    }

    return false;
  };

  const defaultChecked = {
    morningShift: isAdmin ? !!taskDetail?.morningShift : !!taskDetail?.morningShiftCustomer,
    afternoonShift: isAdmin ? !!taskDetail?.afternoonShift : !!taskDetail?.afternoonShiftCustomer,
    eveningShift: isAdmin ? !!taskDetail?.eveningShift : !!taskDetail?.eveningShiftCustomer,
    otherShift: isAdmin ? !!taskDetail?.otherShift : !!taskDetail?.otherShiftCustomer,
  };

  return (
    <>
      <CommonModal
        width={widthModal}
        visible={visible}
        closable={true}
        footer={null}
        onCancel={onCancel}
        className={classNames(styles.modal, className)}
      >
        <Loader isLoading={isLoadingTaskDetail}>
          <Form form={form}>
            <NameCompanyWithColor companySelected={companySelected} taskDetail={taskDetail} />
            <Row gutter={[20, 10]} className="mt-10">
              <Col span={12}>
                <span className="strong fontsize-20">手配の詳細</span>
              </Col>
              <Col span={12}>
                <Row justify="end">
                  {/* Delete tasks */}
                  <AccessControlMultiCompany
                    accessibleRoles={[UserRole.ADMIN, UserRole.MANAGER, UserRole.OFFICE_MANAGER, UserRole.LEADER]}
                    roleInCompany={currentRoleInCompany}
                  >
                    {!!taskDetail?.metadataCustomerUpdate ? (
                      <CommonButton
                        style={{ width: 120, marginRight: '5px', color: '#757575' }}
                        className={styles.icons}
                        type="default"
                        icon={icTrash}
                        title="削除 "
                        onClick={() => handleDelete(UpdateTaskMode.ALL)}
                      />
                    ) : (
                      <Dropdown
                        overlay={menuDelete}
                        getPopupContainer={(triggerNode: any) => triggerNode.parentElement}
                        placement="bottomLeft"
                        trigger={['click']}
                        arrow
                      >
                        <CommonButton
                          style={{ width: 120, marginRight: '5px', color: '#757575' }}
                          className={styles.icons}
                          type="default"
                          icon={icTrash}
                          title="削除 "
                        />
                      </Dropdown>
                    )}
                  </AccessControlMultiCompany>

                  {/* Others actions */}
                  <AccessControlMultiCompany
                    accessibleRoles={[
                      UserRole.ADMIN,
                      UserRole.MANAGER,
                      UserRole.OFFICE_MANAGER,
                      UserRole.LEADER,
                      UserRole.VENDOR,
                      UserRole.CUSTOMER,
                    ]}
                    hidden={isAdmin && !!taskDetail?.metadataCustomerUpdate}
                    roleInCompany={currentRoleInCompany}
                  >
                    <div>{renderBtn()}</div>
                  </AccessControlMultiCompany>
                </Row>
              </Col>

              <Col span={24}>
                <Space align="center" style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <Space size="large">
                    <Space size="small" align="center">
                      <img src={icCalendarWhite} alt="" className="mt-1" />
                      <div className="strong">{dateUtils.getDate(taskDetail?.startDate)}</div>
                      {isTaskDeleted && <div className="strong text-error">タスクが削除されました</div>}
                    </Space>

                    <AccessControlMultiCompany
                      accessibleRoles={[UserRole.ADMIN, UserRole.LEADER, UserRole.MANAGER, UserRole.OFFICE_MANAGER]}
                      roleInCompany={currentRoleInCompany}
                    >
                      <Space align="center" size="small">
                        <img src={icUserBlack} alt="" className="mb-2" />
                        <Text
                          className="line-1"
                          title={`${(dateUtils.getDate(taskDetail?.createdAt), taskDetail?.creatorName)}`}
                          style={{ fontSize: '13px', fontWeight: 500 }}
                        >
                          {dateUtils.getDate(taskDetail?.createdAt)}, {taskDetail?.creatorName}
                        </Text>
                      </Space>
                    </AccessControlMultiCompany>
                  </Space>

                  <Space size="large" align="center">
                    {ROLES_ADMIN.includes(currentRoleInCompany) &&
                    taskDetail?.metadataCustomerUpdate &&
                    Object.keys(taskDetail?.metadataCustomerUpdate).length ? (
                      <div onClick={() => setOpenModalConfirm(true)} className={styles.customButton}>
                        <img src={icCheck} alt="" />
                        <div className={classNames(styles.titleUnderline, 'strong')}>確認</div>
                      </div>
                    ) : null}

                    {ROLES_ADMIN.includes(currentRoleInCompany) && taskDetail?.reserveStatus ? (
                      <div className={styles.customButton} onClick={() => handleRemoveReserve()}>
                        <img src={icForward} alt="" />
                        <div
                          className={classNames(styles.titleUnderline, 'strong')}
                          style={{ color: 'red', textDecorationColor: 'red' }}
                        >
                          戻す
                        </div>
                      </div>
                    ) : null}

                    {ROLES_ADMIN.includes(currentRoleInCompany) && (
                      <div onClick={() => toggleModal(true)} className={styles.customButton}>
                        <img src={icPaper} alt="" />
                        <div className={classNames(styles.titleUnderline, 'strong')}>変更履歴</div>
                      </div>
                    )}

                    {![UserRole.CUSTOMER, UserRole.VENDOR].includes(currentRoleInCompany) && (
                      <Link
                        to={{
                          pathname: '/works/paper',
                          search: `?constructionSiteId=${taskDetail?.constructionSiteId}&taskId=${taskId}&open=1`,
                        }}
                        href=""
                        target="_blank"
                        rel="noreferrer"
                        className={styles.linkCreate}
                      >
                        <div className={styles.customButton}>
                          <img src={icAddFee} alt="" />
                          <div className={classNames(styles.titleUnderline, 'strong')}>費用請求</div>
                        </div>
                      </Link>
                    )}

                    <AccessControlMultiCompany
                      accessibleRoles={ROLES_ADMIN}
                      hidden={taskDetail?.reserveStatus === 1 || moment(taskDetail?.startDate).isBefore(new Date())}
                      roleInCompany={currentRoleInCompany}
                    >
                      <Dropdown
                        overlay={menuReserve}
                        getPopupContainer={(triggerNode: any) => triggerNode.parentElement}
                        placement="bottomLeft"
                        trigger={['click']}
                        arrow
                      >
                        <div className={styles.customButton}>
                          <img src={icSetting} alt="" />
                          <div className={classNames(styles.titleUnderline, 'strong')}>保留</div>
                        </div>
                      </Dropdown>
                    </AccessControlMultiCompany>

                    {ROLES_ADMIN.includes(currentRoleInCompany) && (
                      <ButtonSendEmail
                        detailsTaskRef={(ref as any) ?? detailsTaskRef}
                        taskDetail={taskDetail}
                        companySelected={companySelected}
                      />
                    )}
                  </Space>
                </Space>
              </Col>
            </Row>

            <Row gutter={[20, 20]} className="mt-10 fixed-header-form" ref={ref ?? detailsTaskRef}>
              <Col span={spanCol1}>
                <div className={styles.colWrap}>
                  <SectionWrapper>
                    <Row gutter={[20, 10]}>
                      <Col span={spanColLabel}>
                        <Space align="center" size={4}>
                          <img src={icCompany} alt="" className="mb-2" />
                          <span className="strong">{taskDetail?.customerCompanyName || emptyData}</span>
                        </Space>
                      </Col>
                      <Col span={spanColValue} className="fontsize-14 color-text2">
                        <Space align="center" size={4}>
                          <img src={icDoubleUserBlack} alt="" className="mb-2" />
                          <span className="strong">{taskDetail?.customerName || emptyData}</span>
                        </Space>
                      </Col>

                      <Col span={spanColLabel} className="fontsize-14 text-bold">
                        プロジェクト名
                      </Col>
                      <Col span={spanColValue} className="fontsize-14 color-text2">
                        {[
                          UserRole.ADMIN,
                          UserRole.OFFICE_MANAGER,
                          UserRole.MANAGER,
                          UserRole.CUSTOMER,
                          UserRole.VENDOR,
                        ].includes(currentRoleInCompany) ? (
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={`/works/information?constructionSiteId=${taskDetail?.constructionSiteId}`}
                          >
                            {taskDetail?.constructionSiteName || emptyData}
                          </a>
                        ) : (
                          taskDetail?.constructionSiteName || emptyData
                        )}
                      </Col>

                      <Col span={spanColLabel} className="fontsize-14 text-bold">
                        住所
                      </Col>
                      <Col span={spanColValue} className="fontsize-14 color-text2">
                        <AddressProject address={taskDetail?.address} />
                      </Col>

                      <Col span={spanColLabel} className="fontsize-14 text-bold">
                        プロジェクトリーダー
                      </Col>
                      <Col span={spanColValue} className="fontsize-14 color-text2">
                        {taskDetail?.leaderTaskPhone
                          ? `${taskDetail?.leaderTaskName} - ${taskDetail?.leaderTaskPhone}`
                          : taskDetail?.leaderTaskName || emptyData}
                      </Col>

                      <Col span={spanColLabel} className="fontsize-14 text-bold">
                        プロジェクト概要・メモ
                      </Col>
                      <Col span={spanColValue} className="fontsize-14 color-text2">
                        <div className="textPreWrap"> {taskDetail?.noteConstructionSite || emptyData}</div>

                        {isCanViewMetadata && isAppearMetadata('noteConstructionSite') ? (
                          <div className="text-green textPreWrap">
                            {metadataCustomerUpdate?.noteConstructionSite || emptyData}
                          </div>
                        ) : null}

                        <Space direction="vertical" size={1}>
                          {taskDetail?.filesConstructionSite?.map((file: any, index: number) => (
                            <Typography.Link target="_blank" href={file.url} key={file?.id || index}>
                              {file?.name}
                            </Typography.Link>
                          ))}
                        </Space>
                      </Col>
                    </Row>
                  </SectionWrapper>

                  <SectionWrapper className="mt-10">
                    <Space size="small" align="center">
                      <img src={icCalendarWhite} alt="" className="mt-1" />
                      <div className="strong">手配情報</div>
                    </Space>

                    <Row gutter={[20, 10]} className="mt-10">
                      <Col span={spanColLabel} className="fontsize-14 text-bold">
                        手配名
                      </Col>
                      <Col span={spanColValue} className="fontsize-14 color-text2">
                        <div>{taskDetail?.name || emptyData}</div>
                        {isCanViewMetadata && isAppearMetadata('name') ? (
                          <div className="text-green">{metadataCustomerUpdate?.name}</div>
                        ) : null}
                      </Col>

                      <Col span={spanColLabel} className="fontsize-14   text-bold">
                        <LabelDetailsTime taskDetail={taskDetail} />
                      </Col>
                      <Col span={spanColValue} className="fontsize-14 color-text2">
                        <DetailsTime taskDetail={taskDetail} />

                        <DetailsListDayOffs taskDetail={taskDetail} />

                        {isCanViewMetadata &&
                        metadataCustomerUpdate &&
                        (taskDetail?.seriesFrom != (metadataCustomerUpdate as any)?.date?.from ||
                          taskDetail?.seriesTo != (metadataCustomerUpdate as any)?.date?.to) ? (
                          <MetadataTime taskDetail={taskDetail} metadataCustomerUpdate={metadataCustomerUpdate} />
                        ) : null}

                        {isCanViewMetadata && (
                          <MetadataListDayOffs
                            taskDetail={taskDetail}
                            metadataCustomerUpdate={metadataCustomerUpdate}
                          />
                        )}
                      </Col>

                      <AccessControlMultiCompany
                        accessibleRoles={[
                          UserRole.ADMIN,
                          UserRole.CUSTOMER,
                          UserRole.LEADER,
                          UserRole.OFFICE_MANAGER,
                          UserRole.MANAGER,
                          UserRole.VENDOR,
                        ]}
                        roleInCompany={currentRoleInCompany}
                      >
                        <Col span={spanColLabel} className="fontsize-14 text-bold">
                          希望時間
                        </Col>
                        <Col span={spanColValue} className="fontsize-14 color-text2">
                          <DetailsTimeDesire taskDetail={taskDetail} />

                          {isCanViewMetadata && <MetadataTimeDesire taskDetail={taskDetail} />}
                        </Col>
                      </AccessControlMultiCompany>

                      {isCanViewShifts() && (
                        <>
                          <Col span={spanColLabel} className="fontsize-14 text-bold">
                            プロジェクト時間
                          </Col>

                          <Col span={spanColValue} className="fontsize-14 color-text2 pointer-events-none">
                            <CommonSpace gap={8} align="center">
                              {profileCompany?.morningShift || !!taskDetail?.morningShiftCustomer ? (
                                <Checkbox checked={!!taskDetail?.morningShiftCustomer}>午前</Checkbox>
                              ) : null}
                              {profileCompany?.afternoonShift || !!taskDetail?.afternoonShiftCustomer ? (
                                <Checkbox checked={!!taskDetail?.afternoonShiftCustomer}>午後</Checkbox>
                              ) : null}
                              {profileCompany?.eveningShift || !!taskDetail?.eveningShiftCustomer ? (
                                <Checkbox checked={!!taskDetail?.eveningShiftCustomer}>夜間</Checkbox>
                              ) : null}
                              <Checkbox checked={!!taskDetail?.otherShiftCustomer}>オプション</Checkbox>
                            </CommonSpace>

                            {isCanViewMetadata &&
                            !!metadataCustomerUpdate &&
                            (isAppearMetadata('morningShiftCustomer') ||
                              isAppearMetadata('afternoonShiftCustomer') ||
                              isAppearMetadata('eveningShiftCustomer') ||
                              isAppearMetadata('otherShiftCustomer')) ? (
                              <CommonSpace gap={8} align="center">
                                {profileCompany?.morningShift || taskDetail?.morningShiftCustomer ? (
                                  <Checkbox
                                    className={isAppearMetadata('morningShiftCustomer') ? styles.greenCheckbox : ''}
                                    checked={metadataCustomerUpdate?.morningShiftCustomer}
                                  >
                                    午前
                                  </Checkbox>
                                ) : null}
                                {profileCompany?.afternoonShift || taskDetail?.afternoonShift ? (
                                  <Checkbox
                                    className={isAppearMetadata('afternoonShiftCustomer') ? styles.greenCheckbox : ''}
                                    checked={metadataCustomerUpdate?.afternoonShiftCustomer}
                                  >
                                    午後
                                  </Checkbox>
                                ) : null}
                                {profileCompany?.eveningShift || taskDetail?.eveningShift ? (
                                  <Checkbox
                                    className={isAppearMetadata('eveningShiftCustomer') ? styles.greenCheckbox : ''}
                                    checked={metadataCustomerUpdate?.eveningShiftCustomer}
                                  >
                                    夜間
                                  </Checkbox>
                                ) : null}

                                <Checkbox
                                  className={isAppearMetadata('otherShiftCustomer') ? styles.greenCheckbox : ''}
                                  checked={metadataCustomerUpdate?.otherShiftCustomer}
                                >
                                  オプション
                                </Checkbox>
                              </CommonSpace>
                            ) : null}
                          </Col>
                        </>
                      )}

                      {/* Chung chi cua customer */}
                      {isCanViewCertificateCustomer() && (
                        <>
                          <Col span={spanColLabel} className="fontsize-14 color-red text-bold">
                            人工
                          </Col>
                          <Col span={spanColValue} className="fontsize-14 color-text2 pointer-events-none">
                            <CertificateCustomer type="morningShift" taskDetail={taskDetail} />

                            {checkTaskSeparatedShift(taskDetail) && (
                              <CertificateCustomer type="afternoonShift" taskDetail={taskDetail} />
                            )}

                            <CertificateCustomer type="eveningShift" taskDetail={taskDetail} />
                            <CertificateCustomer type="otherShift" taskDetail={taskDetail} />

                            {isCanViewMetadata && <MetaDataCertificateCustomer taskDetail={taskDetail} />}

                            {/* {!checkTaskSeparatedShift(taskDetail) && (
                              <div className="strong text-error">
                                システムは「人工」の値を分割しました。再度チェックしてください。
                              </div>
                            )} */}
                          </Col>
                        </>
                      )}

                      <Col span={spanColLabel} className="fontsize-14 text-bold">
                        仕事の内容
                      </Col>

                      <Col span={spanColValue} className="fontsize-14 color-text2">
                        <div className="textPreWrap">{taskDetail?.description || emptyData}</div>
                        {isCanViewMetadata && isAppearMetadata('description') ? (
                          <div className="text-green textPreWrap ">{metadataCustomerUpdate?.description}</div>
                        ) : null}

                        <div className={styles.listFiles}>
                          {taskDetail?.files.map((file: any, index: number) => (
                            <Typography.Link target="_blank" href={file.url} key={file?.id || index}>
                              {file?.name}
                            </Typography.Link>
                          ))}

                          {isDifferentFile
                            ? metadataCustomerUpdate?.files.map((file: any, index: number) => (
                                <Typography.Link target="_blank" type="success" href={file.url} key={file?.id || index}>
                                  {file?.name}
                                </Typography.Link>
                              ))
                            : null}
                        </div>
                      </Col>
                    </Row>
                  </SectionWrapper>

                  <AccessControlMultiCompany accessibleRoles={ROLES_ADMIN} roleInCompany={currentRoleInCompany}>
                    <SectionWrapper className="mt-10">
                      <Row gutter={[20, 10]}>
                        <Col span={spanColLabel}>
                          <Space size={4}>
                            <img src={icNoteTask} alt="" />
                            <span className="strong">メモ</span>
                          </Space>
                        </Col>

                        <Col span={spanColValue} className="fontsize-14 color-text2">
                          {taskDetail?.note || emptyData}
                        </Col>
                      </Row>
                    </SectionWrapper>
                  </AccessControlMultiCompany>
                </div>
              </Col>

              <Col span={spanCol2}>
                <div className={styles.colWrap}>
                  {/* Morning shift */}

                  <AccessControlMultiCompany
                    accessibleRoles={[
                      UserRole.ADMIN,
                      UserRole.MANAGER,
                      UserRole.OFFICE_MANAGER,
                      UserRole.LEADER,
                      UserRole.VENDOR,
                      UserRole.WORKER,
                      UserRole.PART_TIME_WORKER,
                      UserRole.OTHER_WORKER,
                      UserRole.VENDOR_WORKER,
                      UserRole.CUSTOMER,
                    ]}
                    roleInCompany={currentRoleInCompany}
                    hidden={!isCanViewMorningShift()}
                  >
                    <Collapse
                      expandIconPosition="right"
                      className={styles.accordionShift}
                      defaultActiveKey={[ShipOfDay.MORNING]}
                      style={{
                        display: isCanViewTime(defaultChecked.morningShift, 'morningShift') ? '' : 'none',
                      }}
                    >
                      <Collapse.Panel key={ShipOfDay.MORNING} header="午前">
                        {isCanViewTime(defaultChecked.morningShift, 'morningShift') && (
                          <Row gutter={8} className="plr-10">
                            <Col span={8} className="fontsize-14 text-bold">
                              開始時刻 - 終了時刻
                            </Col>
                            <Col span={16} className="fontsize-14 color-text2">
                              {taskDetail?.morningFrom ?? profileCompany?.morningFrom}-
                              {taskDetail?.morningTo ?? profileCompany?.morningTo}
                            </Col>
                          </Row>
                        )}

                        {isCanViewOtherField.morningShift && (
                          <>
                            {isCanViewMaxWorker && (
                              <>
                                <Divider className="mt-5" />
                                <Row gutter={8} className="plr-10">
                                  <Col span={8} className="fontsize-14 text-bold">
                                    人工
                                  </Col>
                                  <Col span={16} className="fontsize-14 color-text2">
                                    {taskDetail?.workerMorning}
                                  </Col>
                                </Row>
                              </>
                            )}

                            <AccessControlMultiCompany
                              accessibleRoles={[
                                UserRole.ADMIN,
                                UserRole.MANAGER,
                                UserRole.OFFICE_MANAGER,
                                UserRole.LEADER,
                                UserRole.VENDOR,
                                UserRole.OTHER_WORKER,
                                UserRole.WORKER,
                                UserRole.PART_TIME_WORKER,
                              ]}
                              roleInCompany={currentRoleInCompany}
                            >
                              <Divider className="mt-5" />
                              <Row gutter={8} className="plr-10">
                                <Col span={8} className="fontsize-14   text-bold">
                                  サプライヤー
                                </Col>
                                <Col span={16} className="fontsize-14 color-text2">
                                  <Row gutter={8}>
                                    {vendors?.map((vendor: any) =>
                                      vendor?.morningShift ? (
                                        <Col span={24} key={vendor?.id}>
                                          <VenderItem
                                            vendor={vendor}
                                            isAdmin={isAdmin}
                                            time={WORKING_TIME.MORNING}
                                            disableLink={disableLinkVendor}
                                          />
                                        </Col>
                                      ) : null
                                    )}
                                  </Row>
                                </Col>
                              </Row>
                              <Divider className="mt-5" />

                              <Row gutter={8} className="plr-10">
                                <Col span={8} className="fontsize-14   text-bold">
                                  スタッフ
                                </Col>
                                <Col span={16} className="fontsize-14 color-text2">
                                  <Row gutter={8}>
                                    {workers?.map((worker: any) =>
                                      worker?.morningShift ? (
                                        <Col span={12} key={worker?.id}>
                                          <StaffItem worker={worker} isCanViewLink={isCanViewLinkWorker(worker)} />
                                        </Col>
                                      ) : null
                                    )}
                                  </Row>
                                </Col>
                              </Row>
                            </AccessControlMultiCompany>
                          </>
                        )}
                      </Collapse.Panel>
                    </Collapse>
                  </AccessControlMultiCompany>

                  {/* Afternoon shift */}

                  <AccessControlMultiCompany
                    accessibleRoles={[
                      UserRole.ADMIN,
                      UserRole.MANAGER,
                      UserRole.OFFICE_MANAGER,
                      UserRole.LEADER,
                      UserRole.VENDOR,
                      UserRole.WORKER,
                      UserRole.PART_TIME_WORKER,
                      UserRole.OTHER_WORKER,
                      UserRole.VENDOR_WORKER,
                      UserRole.CUSTOMER,
                    ]}
                    roleInCompany={currentRoleInCompany}
                    hidden={!isCanViewAfternoonShift()}
                  >
                    <Collapse
                      expandIconPosition="right"
                      className={styles.accordionShift}
                      defaultActiveKey={[ShipOfDay.AFTERNOON]}
                      style={{
                        display: isCanViewTime(defaultChecked.afternoonShift, 'afternoonShift') ? '' : 'none',
                      }}
                    >
                      <Collapse.Panel key={ShipOfDay.AFTERNOON} header="午後">
                        {isCanViewTime(defaultChecked.afternoonShift, 'afternoonShift') && (
                          <Row gutter={8} className="plr-10">
                            <Col span={8} className="fontsize-14 text-bold">
                              開始時刻 - 終了時刻
                            </Col>
                            <Col span={16} className="fontsize-14 color-text2">
                              {taskDetail?.afternoonFrom ?? profileCompany?.afternoonFrom}-
                              {taskDetail?.afternoonTo ?? profileCompany?.afternoonTo}
                            </Col>
                          </Row>
                        )}

                        {isCanViewOtherField.afternoonShift && (
                          <>
                            {isCanViewMaxWorker && (
                              <>
                                <Divider className="mt-5" />
                                <Row gutter={8} className="plr-10">
                                  <Col span={8} className="fontsize-14 text-bold">
                                    人工
                                  </Col>
                                  <Col span={16} className="fontsize-14 color-text2">
                                    {taskDetail?.workerAfternoon}
                                  </Col>
                                </Row>
                              </>
                            )}

                            <AccessControlMultiCompany
                              accessibleRoles={[
                                UserRole.ADMIN,
                                UserRole.MANAGER,
                                UserRole.OFFICE_MANAGER,
                                UserRole.LEADER,
                                UserRole.VENDOR,
                                UserRole.OTHER_WORKER,
                                UserRole.WORKER,
                                UserRole.PART_TIME_WORKER,
                              ]}
                              roleInCompany={currentRoleInCompany}
                            >
                              <Divider className="mt-5" />
                              <Row gutter={8} className="plr-10">
                                <Col span={8} className="fontsize-14 text-bold">
                                  サプライヤー
                                </Col>
                                <Col span={16} className="fontsize-14 color-text2">
                                  <Row gutter={8}>
                                    {vendors?.map((vendor: any) =>
                                      vendor?.afternoonShift ? (
                                        <Col span={24} key={vendor?.id}>
                                          <VenderItem
                                            vendor={vendor}
                                            isAdmin={isAdmin}
                                            time={WORKING_TIME.AFTERNOON}
                                            disableLink={disableLinkVendor}
                                          />
                                        </Col>
                                      ) : null
                                    )}
                                  </Row>
                                </Col>
                              </Row>
                              <Divider className="mt-5" />
                              <Row gutter={8} className="plr-10">
                                <Col span={8} className="fontsize-14   text-bold">
                                  スタッフ
                                </Col>
                                <Col span={16} className="fontsize-14 color-text2">
                                  <Row gutter={8}>
                                    {workers?.map((worker: any) =>
                                      worker?.afternoonShift ? (
                                        <Col span={12} key={worker?.id}>
                                          <StaffItem worker={worker} isCanViewLink={isCanViewLinkWorker(worker)} />
                                        </Col>
                                      ) : null
                                    )}
                                  </Row>
                                </Col>
                              </Row>
                            </AccessControlMultiCompany>
                          </>
                        )}
                      </Collapse.Panel>
                    </Collapse>
                  </AccessControlMultiCompany>

                  {/* Evening shift */}
                  <AccessControlMultiCompany
                    accessibleRoles={[
                      UserRole.ADMIN,
                      UserRole.MANAGER,
                      UserRole.OFFICE_MANAGER,
                      UserRole.LEADER,
                      UserRole.VENDOR,
                      UserRole.WORKER,
                      UserRole.PART_TIME_WORKER,
                      UserRole.OTHER_WORKER,
                      UserRole.VENDOR_WORKER,
                      UserRole.CUSTOMER,
                    ]}
                    roleInCompany={currentRoleInCompany}
                    hidden={!isCanViewEveningShift()}
                  >
                    <Collapse
                      expandIconPosition="right"
                      className={styles.accordionShift}
                      defaultActiveKey={[ShipOfDay.EVENING]}
                      style={{
                        display: isCanViewTime(defaultChecked.eveningShift, 'eveningShift') ? '' : 'none',
                      }}
                    >
                      <Collapse.Panel key={ShipOfDay.EVENING} header="夜間">
                        {isCanViewTime(defaultChecked.eveningShift, 'eveningShift') && (
                          <Row gutter={8} className="plr-10">
                            <Col span={8} className="fontsize-14 text-bold">
                              開始時刻 - 終了時刻
                            </Col>
                            <Col span={16} className="fontsize-14 color-text2">
                              {taskDetail?.eveningFrom ?? profileCompany?.eveningFrom}-
                              {taskDetail?.eveningTo ?? profileCompany?.eveningTo}
                            </Col>
                          </Row>
                        )}

                        {isCanViewOtherField.eveningShift && (
                          <>
                            {isCanViewMaxWorker && (
                              <>
                                <Divider className="mt-5" />
                                <Row gutter={8} className="plr-10">
                                  <Col span={8} className="fontsize-14   text-bold">
                                    人工
                                  </Col>
                                  <Col span={16} className="fontsize-14 color-text2">
                                    {taskDetail?.workerEvening}
                                  </Col>
                                </Row>
                                <Divider className="mt-5" />
                              </>
                            )}

                            <AccessControlMultiCompany
                              accessibleRoles={[
                                UserRole.ADMIN,
                                UserRole.MANAGER,
                                UserRole.OFFICE_MANAGER,
                                UserRole.LEADER,
                                UserRole.VENDOR,
                                UserRole.OTHER_WORKER,
                                UserRole.WORKER,
                                UserRole.PART_TIME_WORKER,
                              ]}
                              roleInCompany={currentRoleInCompany}
                            >
                              <Row gutter={8} className="plr-10">
                                <Col span={8} className="fontsize-14   text-bold">
                                  サプライヤー
                                </Col>
                                <Col span={16} className="fontsize-14 color-text2">
                                  <Row gutter={8}>
                                    {vendors?.map((vendor: any) =>
                                      vendor?.eveningShift ? (
                                        <Col span={24} key={vendor?.id}>
                                          <VenderItem
                                            vendor={vendor}
                                            isAdmin={isAdmin}
                                            time={WORKING_TIME.EVENING}
                                            disableLink={disableLinkVendor}
                                          />
                                        </Col>
                                      ) : null
                                    )}
                                  </Row>
                                </Col>
                              </Row>
                              <Divider className="mt-5" />

                              <Row gutter={8} className="plr-10">
                                <Col span={8} className="fontsize-14   text-bold">
                                  スタッフ
                                </Col>
                                <Col span={16} className="fontsize-14 color-text2">
                                  <Row gutter={8}>
                                    {workers?.map((worker: any) =>
                                      worker?.eveningShift ? (
                                        <Col span={12} key={worker.id}>
                                          <StaffItem worker={worker} isCanViewLink={isCanViewLinkWorker(worker)} />
                                        </Col>
                                      ) : null
                                    )}
                                  </Row>
                                </Col>
                              </Row>
                            </AccessControlMultiCompany>
                          </>
                        )}
                      </Collapse.Panel>
                    </Collapse>
                  </AccessControlMultiCompany>

                  {/* otherShift */}
                  <AccessControlMultiCompany
                    accessibleRoles={[
                      UserRole.ADMIN,
                      UserRole.MANAGER,
                      UserRole.OFFICE_MANAGER,
                      UserRole.LEADER,
                      UserRole.VENDOR,
                      UserRole.WORKER,
                      UserRole.PART_TIME_WORKER,
                      UserRole.OTHER_WORKER,
                      UserRole.VENDOR_WORKER,
                      UserRole.CUSTOMER,
                    ]}
                    roleInCompany={currentRoleInCompany}
                    hidden={!isCanViewOtherShift()}
                  >
                    <Collapse
                      expandIconPosition="right"
                      className={styles.accordionShift}
                      defaultActiveKey={[ShipOfDay.OTHER]}
                      style={{
                        display: isCanViewTime(defaultChecked.otherShift, 'otherShift') ? '' : 'none',
                      }}
                    >
                      <Collapse.Panel key={ShipOfDay.OTHER} header="オプション">
                        {isCanViewTime(defaultChecked.otherShift, 'otherShift') && (
                          <Row gutter={8} className="plr-10">
                            <Col span={8} className="fontsize-14 text-bold">
                              開始時刻 - 終了時刻
                            </Col>
                            <Col span={16} className="fontsize-14 color-text2">
                              {taskDetail?.otherFrom ?? profileCompany?.otherFrom}-
                              {taskDetail?.otherTo ?? profileCompany?.otherTo}
                            </Col>
                          </Row>
                        )}

                        {isCanViewOtherField.otherShift && (
                          <>
                            {isCanViewMaxWorker && (
                              <>
                                <Divider className="mt-5" />
                                <Row gutter={8} className="plr-10">
                                  <Col span={8} className="fontsize-14   text-bold">
                                    人工
                                  </Col>
                                  <Col span={16} className="fontsize-14 color-text2">
                                    {taskDetail?.workerOther}
                                  </Col>
                                </Row>
                              </>
                            )}

                            <AccessControlMultiCompany
                              accessibleRoles={[
                                UserRole.ADMIN,
                                UserRole.MANAGER,
                                UserRole.OFFICE_MANAGER,
                                UserRole.LEADER,
                                UserRole.VENDOR,
                                UserRole.OTHER_WORKER,
                                UserRole.WORKER,
                                UserRole.PART_TIME_WORKER,
                              ]}
                              roleInCompany={currentRoleInCompany}
                            >
                              <Divider className="mt-5" />
                              <Row gutter={8} className="plr-10">
                                <Col span={8} className="fontsize-14   text-bold">
                                  サプライヤー
                                </Col>
                                <Col span={16} className="fontsize-14 color-text2">
                                  <Row gutter={8}>
                                    {vendors?.map((vendor: any) => {
                                      const showPrice =
                                        [UserRole.ADMIN, UserRole.MANAGER, UserRole.OFFICE_MANAGER].includes(
                                          currentRoleInCompany
                                        ) || vendor?.userId === profile.id;

                                      return vendor?.otherShift ? (
                                        <Col span={24} key={vendor?.id}>
                                          <VenderItem
                                            vendor={vendor}
                                            isAdmin={isAdmin}
                                            time={WORKING_TIME.OTHER}
                                            showPrice={showPrice}
                                            disableLink={disableLinkVendor}
                                          />
                                        </Col>
                                      ) : null;
                                    })}
                                  </Row>
                                </Col>
                              </Row>

                              <Divider className="mt-5" />
                              <Row gutter={8} className="plr-10">
                                <Col span={8} className="fontsize-14 text-bold">
                                  スタッフ
                                </Col>
                                <Col span={16} className="fontsize-14 color-text2">
                                  <Row gutter={8}>
                                    {workers?.map((worker: any) => {
                                      return worker?.otherShift ? (
                                        <Col span={12} key={worker?.id}>
                                          <StaffItem
                                            worker={worker}
                                            hiddenPrice={false}
                                            isCanViewLink={isCanViewLinkWorker(worker)}
                                          />
                                        </Col>
                                      ) : null;
                                    })}
                                  </Row>
                                </Col>
                              </Row>
                            </AccessControlMultiCompany>
                          </>
                        )}
                      </Collapse.Panel>
                    </Collapse>
                  </AccessControlMultiCompany>

                  {/* Start Certificate vendor */}
                  <AccessControlMultiCompany
                    accessibleRoles={[UserRole.ADMIN, UserRole.MANAGER, UserRole.OFFICE_MANAGER, UserRole.VENDOR]}
                    roleInCompany={currentRoleInCompany}
                  >
                    {getNumberVendorHaveCertificate({ profile, taskDetail, currentRoleInCompany }) > 0 && (
                      <Row gutter={8} className="pb-10 mt-10">
                        <Col span={24} className="fontsize-14 text-bold">
                          サプライヤーのライセンス
                        </Col>
                        <Col span={24} className="fontsize-14 color-text2">
                          <Row>
                            {taskDetail?.vendors.map((vendor) => {
                              // Nếu vendor nào không được add chứng chỉ thì bỏ qua
                              if (!vendor?.metadataUnitPriceCertificate) return null;

                              // Nếu người xem là vendor thì chỉ được xem chứng chỉ của mỗi nó
                              if (currentRoleInCompany === UserRole.VENDOR && profile?.id !== vendor?.userId) {
                                return null;
                              }

                              return (
                                <ItemVendorPicker
                                  key={vendor.id}
                                  vendor={vendor}
                                  isActive={true}
                                  type="certificateVendor"
                                  showTextAdd={false}
                                  showIcons={false}
                                  disable
                                  showIconRemoveVendor={false}
                                />
                              );
                            })}
                          </Row>
                        </Col>
                      </Row>
                    )}
                  </AccessControlMultiCompany>
                  {/* End Certificate vendor */}
                </div>
              </Col>
            </Row>

            {/* Modal update task */}
            {showEditTaskModal && (
              <UpdateTaskModal
                modeUpdate={modeUpdate}
                taskId={taskId}
                handleClose={() => setShowEditTaskModal(false)}
                task={task}
                setFilterCalendar={setFilterCalendar}
                defaultCompanySelected={companySelected}
              />
            )}

            {/* Modal confirm approve */}
            {openModalConfirm && (
              <ModalConfirmCustomerUpdate
                setOpenModalConfirm={setOpenModalConfirm}
                taskDetail={taskDetail}
                metadataCustomerUpdate={metadataCustomerUpdate}
              />
            )}
          </Form>
        </Loader>
      </CommonModal>

      {/* History Customer Edit */}
      <ModalInforDetailsEdit
        taskId={taskId}
        open={openModalDetailsEdit}
        toggleOpen={toggleModal}
        companySelected={companySelected}
      />
    </>
  );
});
