const icons = {
  download: {
    gray: require('./icons/download.svg').default,
  },
  file: {
    gray: require('./icons/pin.svg').default,
    black: require('./icons/pin-black.svg').default,
    white: require('./icons/pin-white.svg').default,
  },
  eye: {
    gray: require('./icons/eye.svg').default,
    black: require('./icons/eye-black.svg').default,
  },
  arrow: {
    drag: require('./icons/ic_arrow-drag.png').default,
  },
  block: {
    gray: require('./icons/slash.svg').default,
    black: require('./icons/slash-black.svg').default,
  },
  trash: {
    gray: require('./icons/trash-2.svg').default,
    black: require('./icons/trash-2-black.svg').default,
  },
  pen: {
    gray: require('./icons/edit-3.svg').default,
    black: require('./icons/edit-3-black.svg').default,
  },
  loudspeaker: {
    white: require('./icons/loudspeaker-white.svg').default,
  },
  plus: {
    outlineGray: require('./icons/add_circle_outline.svg').default,
  },
  unlock: {
    gray: require('./icons/unlock.svg').default,
    black: require('./icons/unlock-black.svg').default,
  },
} as const;

export default icons;
