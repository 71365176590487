import { Badge, Button, Image, Tooltip } from 'antd';
import icBill from 'assets/icons/bill.svg';
import icBriefcase from 'assets/icons/briefcase.svg';
import icCalendarSb from 'assets/icons/calendar.svg';
import icClipboard from 'assets/icons/clipboard1.svg';
import icCustomer from 'assets/icons/customer.svg';
import icEmail from 'assets/icons/email.svg';
import icInsursance from 'assets/icons/insurance.svg';
import openAccount from 'assets/icons/open-account.svg';
import icRegisterShift from 'assets/icons/register-shift.svg';
import icReport from 'assets/icons/report-revenue.svg';
import icWorkTable from 'assets/icons/work-table.svg';
import icArrowLeftCircle from 'assets/icons/arrow-left-circle.svg';
import icSalary from 'assets/icons/salary.svg';
import icStaff from 'assets/icons/staff.svg';
import icUsers from 'assets/icons/users.svg';
import icVendor from 'assets/icons/vendor.svg';
import icWorks from 'assets/icons/works.svg';
import classNames from 'classnames';
import { KEY_SIDE_BAR, UserRole } from 'common';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import styles from './styles.module.scss';
import Text from 'antd/lib/typography/Text';
import { PAY_ROLL_VIEWERS, SALARY_VIEWER } from 'common/const';
import useBadgeCountPaper from 'hooks/useBadgeCountPaper';
import useProfile from 'hooks/useProfile';
import useRoleId from 'hooks/useRoleId';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import queryKeys from 'common/queryKeys';
import { getMenuSideBar, updateMenuSidebar } from 'api/constructionSite';
import { keyBy } from 'lodash';
import Loader from 'components/Loader';
import useViewRoutes from 'hooks/useViewRoutes';
import { Route } from 'common/interface';
import { checkPermissionSideNav } from './utils';
import { useSideNavContext } from 'context/SideNavContext';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import icons from 'assets/icons';

const USER_SCHEDULE_ACCESS = [
  UserRole.ADMIN,
  UserRole.MANAGER,
  UserRole.OFFICE_MANAGER,
  UserRole.WORKER,
  UserRole.PART_TIME_WORKER,
  UserRole.OTHER_WORKER,
  UserRole.LEADER,
];

const PAPER_ACCESS = [
  UserRole.ADMIN,
  UserRole.OFFICE_MANAGER,
  UserRole.CUSTOMER,
  UserRole.VENDOR,
  UserRole.MANAGER,
  UserRole.LEADER,
  UserRole.OTHER_WORKER,
];
const STAFF_ACCESS = [UserRole.ADMIN, UserRole.MANAGER, UserRole.OFFICE_MANAGER, UserRole.LEADER, UserRole.VENDOR];
const VENDOR_ACCESS = [UserRole.ADMIN, UserRole.MANAGER, UserRole.OFFICE_MANAGER];
const CUSTOMER_ACCESS = [UserRole.ADMIN, UserRole.MANAGER, UserRole.OFFICE_MANAGER];
const REVENUE_ACCESS = [UserRole.SUPER_ADMIN, UserRole.ADMIN, UserRole.OFFICE_MANAGER, UserRole.MANAGER];
const INSURANCE_ACCESS = [UserRole.ADMIN, UserRole.OFFICE_MANAGER, UserRole.MANAGER];

export default function SideNav() {
  const queryClient = useQueryClient();
  const location = useLocation();
  const history = useHistory();
  const roleId = useRoleId();
  const profile = useProfile();
  const { countPaper } = useBadgeCountPaper();

  const [selectedKey, setSelectedKey] = useState('1');
  const { isCollapsed, setIsCollapsed } = useSideNavContext();

  const isSuperAdmin = !!profile?.isSuperAdmin;

  const sideNavSalaryV4 = sessionStorage.getItem('side-nav-salary-v4');
  const sideNavSalary = sessionStorage.getItem('side-nav-salary');

  const { isCanViewRevenue } = useViewRoutes();

  const routes: Route[] = useMemo(
    () => [
      {
        key: KEY_SIDE_BAR.CALENDAR,
        text: 'カレンダー',
        url: '/calendar',
        icon: icCalendarSb,
        visible: !isSuperAdmin,
      },
      {
        key: KEY_SIDE_BAR.REGISTER_SHIFT,
        text: 'シフト希望',
        url: '/register-shift',
        icon: icRegisterShift,
        visible: !isSuperAdmin && USER_SCHEDULE_ACCESS.includes(roleId),
      },
      {
        key: KEY_SIDE_BAR.WORK_CALENDAR,
        text: 'プロジェクト一覧',
        url: '/works/calendar',
        icon: icWorks,
        visible: !isSuperAdmin,
      },
      {
        key: KEY_SIDE_BAR.SALARY_V4,
        text: '報酬明細',
        url: sideNavSalaryV4 ? `/${sideNavSalaryV4}` : '/salary-v4',
        icon: icSalary,
        visible: !isSuperAdmin && PAY_ROLL_VIEWERS.includes(roleId),
      },
      {
        key: KEY_SIDE_BAR.REPORT_REVENUE,
        text: isSuperAdmin ? '売上報告' : '経営レポート',
        url: '/report-revenue',
        icon: icReport,
        visible: isCanViewRevenue,
      },
      {
        key: KEY_SIDE_BAR.PAPER,
        text: '書類',
        url: '/paper',
        icon: icBill,
        visible: !isSuperAdmin && PAPER_ACCESS.includes(roleId),
        showNotification: !!countPaper,
      },
      {
        key: KEY_SIDE_BAR.STAFF,
        text: '人事',
        url: '/staff',
        icon: icStaff,
        visible: !isSuperAdmin && STAFF_ACCESS.includes(roleId),
      },
      {
        key: KEY_SIDE_BAR.CUSTOMER,
        text: 'クライアント',
        url: '/customer',
        icon: icCustomer,
        visible: !isSuperAdmin && CUSTOMER_ACCESS.includes(roleId),
      },
      {
        key: KEY_SIDE_BAR.VENDOR,
        text: 'サプライヤー',
        url: '/vendor',
        icon: icVendor,
        visible: !isSuperAdmin && VENDOR_ACCESS.includes(roleId),
      },
      {
        key: KEY_SIDE_BAR.ACCOUNT,
        text: 'アカウント',
        url: '/account',
        icon: icUsers,
        visible: isSuperAdmin,
      },
      {
        key: KEY_SIDE_BAR.COMPANIES,
        text: '企業の管理',
        url: '/companies',
        icon: icBriefcase,
        visible: isSuperAdmin,
      },
      {
        key: KEY_SIDE_BAR.INVITES,
        text: '招待状の管理',
        url: '/invites',
        icon: icEmail,
        visible: isSuperAdmin,
      },
      {
        key: KEY_SIDE_BAR.SUBSCRIPTION_PLAN,
        text: 'プラン情報',
        url: '/subscription-plan',
        icon: icClipboard,
        visible: isSuperAdmin,
      },
      {
        key: KEY_SIDE_BAR.INSURANCE,
        text: '社会保険',
        url: '/insurance',
        icon: icInsursance,
        visible: !isSuperAdmin && INSURANCE_ACCESS.includes(roleId),
      },
      {
        key: KEY_SIDE_BAR.OPEN_ACCOUNT,
        text: '退会管理',
        url: '/open-account',
        icon: openAccount,
        visible: isSuperAdmin,
      },
      {
        key: KEY_SIDE_BAR.SALARY,
        text: '勤務表',
        url: sideNavSalary ? `/${sideNavSalary}` : '/salary',
        icon: icWorkTable,
        visible: !isSuperAdmin && SALARY_VIEWER.includes(roleId),
      },
      {
        key: KEY_SIDE_BAR.CAMPAIGN,
        text: 'キャンペーン管理',
        url: '/campaigns',
        icon: icons.loudspeaker.white,
        visible: isSuperAdmin,
      },
    ],
    [countPaper, isSuperAdmin, roleId, sideNavSalaryV4, sideNavSalary, profile?.company?.isDisableViewRevenue]
  );

  const routesCanView = useMemo(() => routes.filter((route) => route?.visible), [routes]);

  const { data: menuServer, isLoading: isLoadingMenu } = useQuery({
    queryKey: queryKeys.menuSideBar,
    queryFn: getMenuSideBar,
  });

  const items = useMemo(() => keyBy(routesCanView, 'key'), [routesCanView]);

  // default order items menu
  const [orderItems, setOrderItems] = useState(routesCanView.map((route) => route?.key));

  const updateMenuMutation = useMutation({
    mutationFn: updateMenuSidebar,
  });

  // handle drag item menu
  const onDragEnd = (result: DropResult) => {
    const { destination, source, draggableId } = result;

    // if drag outside
    if (!destination) {
      return;
    }

    // If drag and drop at the old position
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const newOrder = Array.from(orderItems);

    newOrder.splice(source.index, 1);
    newOrder.splice(destination.index, 0, draggableId);

    setOrderItems(newOrder);

    // convert data to send to server
    const menuToServer = Object.entries(items).map(([key, value]) => ({
      key,
      position: newOrder.indexOf(key) + 1,
    }));

    // update new menu to server
    updateMenuMutation.mutate(menuToServer);
  };

  // async menu from server
  useEffect(() => {
    if (menuServer?.data) {
      menuServer?.data.sort((a, b) => a.position - b.position);

      const newOrderItems = menuServer.data
        .filter((item) => {
          // check permission to view revenue
          if (item.key === KEY_SIDE_BAR.REPORT_REVENUE) {
            return isCanViewRevenue;
          }

          // check permission to view other item menu
          return checkPermissionSideNav({ routes, itemMenuServer: item });
        })
        .map((item) => item.key);

      // if (orderItems.length <= newOrderItems.length) {
      //   setOrderItems(newOrderItems);
      // }

      setOrderItems(newOrderItems);

      return;
    }

    // if don't have menu from server
    // set default menu
    setOrderItems(routesCanView.map((route) => route?.key));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuServer?.data, routesCanView]);

  // handle active Link in sidebar
  useEffect(() => {
    if (location.pathname === '/') {
      const firstRoute = routes.find((x) => x.visible);
      history.push({
        pathname: firstRoute?.url || '/',
      });
    }

    // Tab payment cùng sidebar với paper
    if (location.pathname === '/payment' || location.pathname === '/create-paper') {
      setSelectedKey(KEY_SIDE_BAR.PAPER);
    }

    if (['/salary', '/bonus', '/time-off'].includes(location.pathname)) {
      setSelectedKey(KEY_SIDE_BAR.SALARY);
    }

    if (['/salary-v4', '/payroll', '/bonus-v4'].includes(location.pathname)) {
      setSelectedKey(KEY_SIDE_BAR.SALARY_V4);
    }

    if (
      ['/works/information', '/works/timekeeping', '/works/paper', '/works/revenueCost'].includes(location.pathname)
    ) {
      setSelectedKey(KEY_SIDE_BAR.WORK_CALENDAR);
    }
  }, [location.pathname, history, routes]);

  //
  useEffect(() => {
    queryClient.invalidateQueries('getTotalUnread');

    routes?.forEach((route) => {
      if (location.pathname.startsWith(route?.url || '###')) {
        setSelectedKey(route?.key);
      }

      if (['/salary-v4', '/salary-v2'].some((path) => location.pathname.startsWith(path))) {
        setSelectedKey('4');
      }
    });
  }, [location.pathname, queryClient, routes]);

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.collapsed]: isCollapsed,
      })}
    >
      <div className={classNames(styles.sideNav)}>
        {/* <img
          src={icArrowLeftCircle}
          alt="Button collapse"
          className={styles.buttonCollapse}
          onClick={() => setIsCollapsed((pre) => !pre)}
        /> */}
        <div
          onClick={() => setIsCollapsed((pre) => !pre)}
          className={classNames(styles.buttonCollapseSideBar, {
            [styles.buttonCollapsedSideBar]: isCollapsed,
          })}
        >
          {isCollapsed ? (
            <MenuUnfoldOutlined
              style={{
                fontSize: '20px',
                color: '#fff',
              }}
            />
          ) : (
            <MenuFoldOutlined
              style={{
                fontSize: '20px',
                color: '#fff',
              }}
            />
          )}
        </div>
        <Loader isLoading={isLoadingMenu}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable-1" type="PERSON">
              {(provided, snapshot) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {orderItems
                    ?.map((key) => items?.[key])
                    ?.map((route, index) => (
                      <Draggable draggableId={route?.key} index={index} key={route?.key}>
                        {(provided, snapshot) => (
                          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <Link
                              to={route?.url}
                              className={classNames(styles.linkItem, {
                                [styles.active]: route?.key === selectedKey,
                              })}
                            >
                              <Tooltip title={isCollapsed ? route.text : ''} placement="right">
                                <img src={route?.icon} alt="" className={styles.icon} />
                              </Tooltip>

                              {route?.showNotification ? (
                                <div className={styles.badge}>
                                  <Badge count={countPaper}>
                                    <Text className={classNames(styles.textLink, 'max-2-line')}>{route?.text}</Text>
                                  </Badge>
                                </div>
                              ) : (
                                <Text className={classNames(styles.textLink, 'max-2-line')}>{route?.text}</Text>
                              )}
                            </Link>
                          </div>
                        )}
                      </Draggable>
                    ))}

                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Loader>
      </div>
    </div>
  );
}
