import {
  CommonStatus,
  CompanyBusinessField,
  CompanyScheduleDay,
  ConstructionSiteType,
  DAY_OF_WEEK,
  Gender,
  HolidayRepeatType,
  HolidayTargetType,
  ModeCalendar,
  PaperType,
  REDUCE_FEE,
  SalaryType,
  ShipOfDay,
  SubscriptionType,
  TYPE_PICKER,
  TimeKeepingOvertimeType,
  TimeKeepingState,
  TimeOffMode,
  TimeOffType,
  TypeFixedCost,
  TypeIncomeTax,
  TypePaymentOffset,
  TypePaymentTerm,
  TypeSalary,
  TypeServiceCharge,
  UserRole,
  WorkPlace,
} from 'common';
import { TypeSalaryItem } from './interface';
import { SHIFT } from './schedule';

export const PAGESIZE = 20;
export const BIG_PAGESIZE = 10000;
export const MIN_PASSWORD = 6;
export const MAX_PASSWORD = 32;
export const MAX_LENGTH = 255;
export const MAX_PERCENT = 100;
export const MIN_PERCENT = 0;
export const MAX_LENGTH_MONEY_FORMAT = 19; // use with input have Yen symbol('¥') and comma(,)
export const MAX_PERCENT_FORMAT = 4; // use with input have % symbol('¥')
export const MAX_LENGTH_MONEY = 15;
export const MAX_LENGTH_CHARTER_CAPITAL = 19;
export const MAX_LENGTH_VENDOR_TASK = 5;
export const PATTERN_EMAIL =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PATTERN_PHONE = /^[+]*[0-9]{10,12}$/;
export const PATTERN_INTEGER = /^[0-9]{1,45}$/;
export const PATTERN_KATAKANA = /[\u{3000}-\u{301C}\u{30A1}-\u{30F6}\u{30FB}-\u{30FE} |一]/mu;
export const PATTERN_FAX = /^\+?[0-9]{8,}$/;
export const PATTERN_SPACE = /^\S+$/;
export const PATTERN_ONLY_TEXT_AND_NUMBER = /^[a-zA-Z0-9]+$/;
export const PATTERN_TWO_NUMBER_DECIMAL = /^[0-9]*(\.[0-9]{0,2})?$/;
export const SMALL_WIDTH_MODAL = 690;
export const BIG_WIDTH_MODAL = 1100;
export const BIGGER_WIDTH_MODAL = 1300;
export const MAX_HOUR_A_DAY = 24;
export const MAX_NUMBER_INPUT = 999999999;
export const BIG_INPUT_NUMBER = 999999999999999;
export const BASE_URL_IMAGE = 'https://shm-shokunin-test-2rcuc6.s3.amazonaws.com/';
export const REMOVE_MESSAGE = 'この項目を削除しますか。';
export const ACCEPT_MESSAGE = 'このリクエストを許可しますか。';
export const REJECT_MESSAGE = 'このリクエストを拒否しますか。';
export const MILLISECOND_A_HOUR = 60 * 60 * 1000;
export const DEFAULT_STEP_MINUTE = 15;
export const MOUSE_ENTER_DELAY = 0.3;
export const MOUSE_LEAVE_DELAY = 0.1;

export const DEFAULT_WORK_MONTH_HOUR = 160;
export const DEFAULT_MONTH = 1;

export const PAYMENT_REQUEST = 'paymentRequest';
export const ESTIMATE_PRICE = 'estimatePrice';

export const MAX_COUNT_AVATAR = 20;

export const SALARY_VIEWER = [
  UserRole.ADMIN,
  UserRole.LEADER,
  UserRole.MANAGER,
  UserRole.OFFICE_MANAGER,
  UserRole.OTHER_WORKER,
  UserRole.PART_TIME_WORKER,
  UserRole.VENDOR_WORKER,
  UserRole.WORKER,
];
export const PAY_ROLL_VIEWERS = [
  UserRole.ADMIN,
  UserRole.LEADER,
  UserRole.MANAGER,
  UserRole.OFFICE_MANAGER,
  UserRole.PART_TIME_WORKER,
  UserRole.WORKER,
];

export const ROLES_MANAGER = [UserRole.ADMIN, UserRole.MANAGER, UserRole.OFFICE_MANAGER, UserRole.LEADER];
export const ROLES_ADMIN = [UserRole.ADMIN, UserRole.MANAGER, UserRole.OFFICE_MANAGER];
export const REVENUE_ACCESS = [UserRole.SUPER_ADMIN, UserRole.ADMIN, UserRole.OFFICE_MANAGER, UserRole.MANAGER];
export const ROLES_HIDE_GENDER = [UserRole.VENDOR, UserRole.CUSTOMER, UserRole.VENDOR_WORKER];
export const ROLES_VIEW_REVENUE = [UserRole.ADMIN, UserRole.MANAGER, UserRole.MANAGER];
export const ROLES_CREATE_PAPER = [...ROLES_ADMIN, UserRole.OTHER_WORKER];
export const ROLES_WORKER = [
  UserRole.WORKER,
  UserRole.PART_TIME_WORKER,
  UserRole.OTHER_WORKER,
  UserRole.VENDOR_WORKER,
  UserRole.LEADER,
];
export const ROLES_CAN_ADD_WORKER_REPORT = [...ROLES_ADMIN, UserRole.CUSTOMER];
export const ROLES_WORKER_TASK = [
  UserRole.WORKER,
  UserRole.PART_TIME_WORKER,
  UserRole.OTHER_WORKER,
  UserRole.VENDOR_WORKER,
];
export const ROLES_STAFF_VIEW_NOTE = [
  UserRole.LEADER,
  UserRole.VENDOR_WORKER,
  UserRole.PART_TIME_WORKER,
  UserRole.OTHER_WORKER,
  UserRole.WORKER,
  UserRole.CUSTOMER,
];

export const listSalaryType = [
  {
    id: SalaryType.HOUR_SALARY,
    name: '時給',
  },
  {
    id: SalaryType.MONTH_SALARY,
    name: '月給',
  },
  {
    id: SalaryType.CONSTRUCTION_SALARY,
    name: 'プロジェクト単価',
  },
  {
    id: SalaryType.SHIFT_SALARY,
    name: 'プロジェクト給',
  },
];

export const listSalaryType1 = [
  {
    id: SalaryType.HOUR_SALARY,
    name: '時給',
  },
  {
    id: SalaryType.MONTH_SALARY,
    name: '月給',
  },
  {
    id: SalaryType.SHIFT_SALARY,
    name: 'プロジェクト給',
  },
];

export const listRolesStaff = [
  {
    id: UserRole.ADMIN,
    name: 'アドミン',
  },
  {
    id: UserRole.MANAGER,
    name: 'マネージャー',
  },
  {
    id: UserRole.OFFICE_MANAGER,
    name: 'オフィスマネージャー',
  },
  {
    id: UserRole.LEADER,
    name: 'リーダー',
  },
  {
    id: UserRole.VENDOR_WORKER,
    name: 'サプライヤーのスタッフ',
  },
  {
    id: UserRole.PART_TIME_WORKER,
    name: 'アルバイト',
  },
  {
    id: UserRole.OTHER_WORKER,
    name: 'フリーランス',
  },
  {
    id: UserRole.WORKER,
    name: '社員',
  },
];

export const listRoles = [
  {
    id: UserRole.ADMIN,
    name: 'アドミン',
  },
  {
    id: UserRole.MANAGER,
    name: 'マネージャー',
  },
  {
    id: UserRole.OFFICE_MANAGER,
    name: 'オフィスマネージャー',
  },
  {
    id: UserRole.LEADER,
    name: 'リーダー',
  },
  {
    id: UserRole.VENDOR_WORKER,
    name: 'サプライヤーのスタッフ',
  },
  {
    id: UserRole.PART_TIME_WORKER,
    name: 'アルバイト',
  },
  {
    id: UserRole.OTHER_WORKER,
    name: 'フリーランス',
  },
  {
    id: UserRole.WORKER,
    name: '社員',
  },
];

export const listDayOfWeek = [
  {
    id: CompanyScheduleDay.SUNDAY,
    name: '日曜日',
  },
  {
    id: CompanyScheduleDay.MONDAY,
    name: '月曜日',
  },
  {
    id: CompanyScheduleDay.TUESDAY,
    name: '火曜日',
  },
  {
    id: CompanyScheduleDay.WEDNESDAY,
    name: '水曜日',
  },
  {
    id: CompanyScheduleDay.THURSDAY,
    name: '木曜日',
  },
  {
    id: CompanyScheduleDay.FRIDAY,
    name: '金曜日',
  },
  {
    id: CompanyScheduleDay.SATURDAY,
    name: '土曜日',
  },
];

export const shiftBlockTime = [
  {
    id: 1,
    name: '0',
  },
  {
    id: 2,
    name: '5',
  },
  {
    id: 3,
    name: '10',
  },
  {
    id: 4,
    name: '15',
  },
  {
    id: 5,
    name: '20',
  },
  {
    id: 6,
    name: '30',
  },
];

export const listDateClosingSalary = [
  {
    id: 0,
    name: '月末日',
  },
  {
    id: 1,
    name: '1',
  },
  {
    id: 2,
    name: '2',
  },
  {
    id: 3,
    name: '3',
  },
  {
    id: 4,
    name: '4',
  },
  {
    id: 5,
    name: '5',
  },
  {
    id: 6,
    name: '6',
  },
  {
    id: 7,
    name: '7',
  },
  {
    id: 8,
    name: '8',
  },
  {
    id: 9,
    name: '9',
  },
  {
    id: 10,
    name: '10',
  },
  {
    id: 11,
    name: '11',
  },
  {
    id: 12,
    name: '12',
  },
  {
    id: 13,
    name: '13',
  },
  {
    id: 14,
    name: '14',
  },
  {
    id: 15,
    name: '15',
  },
  {
    id: 16,
    name: '16',
  },
  {
    id: 17,
    name: '17',
  },
  {
    id: 18,
    name: '18',
  },
  {
    id: 19,
    name: '19',
  },
  {
    id: 20,
    name: '20',
  },
  {
    id: 21,
    name: '21',
  },
  {
    id: 22,
    name: '22',
  },
  {
    id: 23,
    name: '23',
  },
  {
    id: 24,
    name: '24',
  },
  {
    id: 25,
    name: '25',
  },
  {
    id: 26,
    name: '26',
  },
  {
    id: 27,
    name: '27',
  },
  {
    id: 28,
    name: '28',
  },
];

export const listTypePaymentOffsetWithDay = [
  {
    id: TypePaymentOffset.DAY_DEFAULT,
    name: '日',
  },
];

export const listTypePaymentOffsetWithWeek = [
  {
    id: TypePaymentOffset.THIS_WEEK,
    name: '当週',
  },
  {
    id: TypePaymentOffset.NEXT_WEEK,
    name: ' 翌週',
  },
  {
    id: TypePaymentOffset.NEXT_WEEK_TO,
    name: '翌々週',
  },
];

export const listTypePaymentOffsetWithMonth = [
  {
    id: TypePaymentOffset.THIS_MONTH,
    name: '当月', // current month
  },
  {
    id: TypePaymentOffset.NEXT_MONTH,
    name: '翌月', // next month
  },
  {
    id: TypePaymentOffset.NEXT_MONTH_TOO,
    name: '翌々月', // two month later
  },
];

export const listSalaryApplyDate = [
  {
    id: 0,
    name: '当月',
  },

  {
    id: 1,
    name: '翌月',
  },
  {
    id: 2,
    name: '翌々月',
  },
];

export const listTaskNames = [
  { name: '内装仕上工事・揚重' },
  { name: '大工・建具工事' },
  { name: '石・タイル・れんが・ブロック工事' },
  { name: '左官工事' },
  { name: 'とび・土工・しゅんせつ工事' },
  { name: '塗装・防水工事' },
  { name: '屋根・板金・鋼構造物工事' },
  { name: '電気・電気通信工事' },
  { name: '鉄筋工事' },
  { name: 'コンクリート・ほ装工事' },
  { name: 'ガラス工事' },
  { name: '管・水道施設・さく井工事' },
  { name: '解体工事' },
  { name: '機械器具設置工事' },
  { name: '熱絶縁工事' },
  { name: '消防施設工事' },
  { name: '清掃施設工事' },
  { name: '土木一式・造園工事' },
  { name: '建築一式工事' },
  { name: 'その他' },
];

export const listTimekeepingType = [
  {
    id: TimeKeepingState.NORMAL,
    name: '通常 ',
  },
  {
    id: TimeKeepingState.EARLY,
    name: '早退',
  },
  {
    id: TimeKeepingState.LATE,
    name: '遅刻 ',
  },
];

export const listConstructionSiteType = [
  {
    id: ConstructionSiteType.LABOR,
    name: 'タスクベース',
  },
  {
    id: ConstructionSiteType.FIXED_PRICE,
    name: '固定価格',
  },
];

export const listShift = [
  {
    id: ShipOfDay.MORNING,
    name: '午前',
  },
  {
    id: ShipOfDay.AFTERNOON,
    name: '午後',
  },
  {
    id: ShipOfDay.EVENING,
    name: '夜間',
  },
  {
    id: ShipOfDay.OTHER,
    name: 'オプション',
  },
];

// Time keeping in construction site
export const APPROVER_TIMEKEEPING = [UserRole.ADMIN, UserRole.LEADER, UserRole.MANAGER, UserRole.OFFICE_MANAGER];
export const WORKER_TIMEKEEPING = [
  UserRole.WORKER,
  UserRole.OTHER_WORKER,
  UserRole.PART_TIME_WORKER,
  UserRole.VENDOR_WORKER,
];
// End of timekeeping in construction site

export const listHours = Array.from(new Array(24)).map((_, index: number) => ({
  id: index,
  name: index.toString(),
}));
export const listMinutes = Array.from(new Array(60)).map((_, index: number) => ({
  id: index,
  name: index.toString(),
}));

export const listTypeTimeOff = [
  {
    id: TimeOffType.FULL_SALARY,
    name: '有給',
  },
  {
    id: TimeOffType.NO_SALARY,
    name: '無給',
  },
];

export const listModeTimeOff = [
  { id: TimeOffMode.FULL, name: '一日' },
  { id: TimeOffMode.HALF, name: '半休' },
];

export const listDayOffType = [
  { id: TimeOffType.FULL_SALARY, name: '有給休' },
  { id: TimeOffType.NO_SALARY, name: '無給休休' },
];

export const listHolidayTargetType = [
  {
    id: HolidayTargetType.ALL,
    name: '全社',
  },
  {
    id: HolidayTargetType.ROLE,
    name: 'ロール',
  },
  {
    id: HolidayTargetType.USER,
    name: '個人',
  },
];

export const listHolidayRole = [
  {
    id: UserRole.ADMIN,
    name: 'アドミン',
  },
  {
    id: UserRole.MANAGER,
    name: 'マネージャー',
  },
  {
    id: UserRole.OFFICE_MANAGER,
    name: 'オフィスマネージャー',
  },
  {
    id: UserRole.LEADER,
    name: 'リーダー',
  },
  {
    id: UserRole.PART_TIME_WORKER,
    name: 'アルバイト',
  },
  {
    id: UserRole.OTHER_WORKER,
    name: 'フリーランス',
  },
  {
    id: UserRole.WORKER,
    name: '社員',
  },
];

export const listHolidayRoleByDay: { id: number; name: string }[] = listHolidayRole.filter(
  (role) => role.id !== UserRole.OTHER_WORKER
);

export const listRoleSubsidy = [
  {
    id: UserRole.ADMIN,
    name: 'アドミン',
  },
  {
    id: UserRole.MANAGER,
    name: 'マネージャー',
  },
  {
    id: UserRole.OFFICE_MANAGER,
    name: 'オフィスマネージャー',
  },
  {
    id: UserRole.LEADER,
    name: 'リーダー',
  },
  {
    id: UserRole.PART_TIME_WORKER,
    name: 'アルバイト',
  },
  {
    id: UserRole.WORKER,
    name: '社員',
  },
];

export const listWeekDisplay = [
  { value: CompanyScheduleDay.SUNDAY, label: '日', name: 'sunday', workHour: 'sundayHour' },
  {
    value: CompanyScheduleDay.MONDAY,
    label: '月',
    name: 'monday',
    workHour: 'mondayHour',
  },
  {
    value: CompanyScheduleDay.TUESDAY,
    label: '火',
    name: 'tuesday',
    workHour: 'tuesdayHour',
  },
  {
    value: CompanyScheduleDay.WEDNESDAY,
    label: '水',
    name: 'wednesday',
    workHour: 'wednesdayHour',
  },
  {
    value: CompanyScheduleDay.THURSDAY,
    label: '木',
    name: 'thursday',
    workHour: 'thursdayHour',
  },
  {
    value: CompanyScheduleDay.FRIDAY,
    label: '金',
    name: 'friday',
    workHour: 'fridayHour',
  },
  {
    value: CompanyScheduleDay.SATURDAY,
    label: '土',
    name: 'saturday',
    workHour: 'saturdayHour',
  },
];

export const companyHolidayRepeatType = [
  {
    id: HolidayRepeatType.EVERY_WEEK,
    name: '定休日',
  },
  {
    id: HolidayRepeatType.EVERY_YEAR,
    name: '法定休',
  },
  {
    id: HolidayRepeatType.NO_REPEAT,
    name: '法定休',
  },
];

export const timeKeepingState = [
  {
    value: TimeKeepingState.NORMAL,
    label: '通常',
  },
  {
    value: TimeKeepingState.LATE,
    label: '遅刻',
  },
  { value: TimeKeepingState.EARLY, label: '早退' },
];

export const ListOvertimeType = [
  {
    id: TimeKeepingOvertimeType.COMMON,
    name: '時間外',
  },
  {
    id: TimeKeepingOvertimeType.HOLIDAY,
    name: '法定休',
  },
  {
    id: TimeKeepingOvertimeType.WEEKEND,
    name: '定休日',
  },
];

export const listUnemploymentInsuranceType = [
  // {
  //   id: UnemploymentInsurranceType.GENERAL,
  //   name: '一般の事業',
  // },
  // {
  //   id: UnemploymentInsurranceType.CONSTRUCTION,
  //   name: '建設の事業',
  // },
  // {
  //   id: 4,
  //   name: '加入',
  // },
  // {
  //   id: UnemploymentInsurranceType.OTHER,
  //   name: '未加入',
  // },

  // phía be đang yêu cầu gửi lên isDisableUnemploymentInsurance nên phải đảo ngược lại
  {
    id: CommonStatus.INACTIVE,
    name: '加入',
  },
  {
    id: CommonStatus.ACTIVE,
    name: '未加入',
  },
];

export const listSubscriptionType = [
  {
    id: SubscriptionType.ADMIN_BUY,
    name: '銀行振込	',
  },
  {
    id: SubscriptionType.STRIPE,
    name: 'クレジットカード',
  },
];

export enum TypePaymentRequest {
  TRAFFIC = 1,
  ENTERTAINMENT = 2,
  PRIVATE = 3,
  MEETING = 4,
  WELFARE = 5,
  OTHER = 6,
}

export const ListPaymentRequest = [
  {
    id: TypePaymentRequest.TRAFFIC,
    name: '交通費',
  },
  {
    id: TypePaymentRequest.ENTERTAINMENT,
    name: '接待交際費',
  },
  {
    id: TypePaymentRequest.PRIVATE,
    name: '消耗品費',
  },
  {
    id: TypePaymentRequest.MEETING,
    name: '会議費',
  },
  {
    id: TypePaymentRequest.WELFARE,
    name: '福利厚生費',
  },
  {
    id: TypePaymentRequest.OTHER,
    name: 'その他',
  },
];

export const TextTypePaymentRequest = {
  [`${TypePaymentRequest.TRAFFIC}`]: '交通費',
  [`${TypePaymentRequest.ENTERTAINMENT}`]: '接待交際費',
  [`${TypePaymentRequest.PRIVATE}`]: '消耗品費',
  [`${TypePaymentRequest.MEETING}`]: '会議費',
  [`${TypePaymentRequest.WELFARE}`]: '福利厚生費',
  [`${TypePaymentRequest.OTHER}`]: 'その他',
};

export const ListIncomeTax = [
  { id: TypeIncomeTax.A, name: '甲' },
  {
    id: TypeIncomeTax.B,
    name: '乙',
  },
  {
    id: TypeIncomeTax.C,
    name: '丙',
  },
];

export const ListIncomeTax1 = [
  { id: TypeIncomeTax.A, name: '甲' },
  {
    id: TypeIncomeTax.B,
    name: '乙',
  },
];

export const ListMonth = [
  { id: 1, name: '1月' },
  {
    id: 2,
    name: '2月',
  },
  {
    id: 3,
    name: '3月',
  },
  {
    id: 4,
    name: '4月',
  },
  {
    id: 5,
    name: '5月',
  },
  {
    id: 6,
    name: '6月',
  },
  {
    id: 7,
    name: '7月',
  },
  {
    id: 8,
    name: '8月',
  },
  {
    id: 9,
    name: '9月',
  },
  {
    id: 10,
    name: '10月',
  },
  {
    id: 11,
    name: '11月',
  },
  {
    id: 12,
    name: '12月',
  },
];
export const view: any = {
  [ModeCalendar.MONTH]: 'month',
  [ModeCalendar.WEEK]: 'week',
  [ModeCalendar.DAY]: 'day',
};

export const listTypeFixedCost = [
  {
    id: TypeFixedCost.REGULAR,
    name: '定期',
  },
  {
    id: TypeFixedCost.IRREGULAR,
    name: '不定期',
  },
];

export const listTypePaymentTerm = [
  { id: TypePaymentTerm.EVERY_DAY, name: '日' },
  {
    id: TypePaymentTerm.EVERY_WEEK,
    name: '週',
  },
  {
    id: TypePaymentTerm.EVERY_MONTH,
    name: '月',
  },
];

export const END_DAY_MONTH = 0;
export const PaymentDateInWeek = [0, 1, 2, 3, 4, 5, 6];

// do not change order of this list
export const listPaymentDateInWeek = [
  {
    id: CompanyScheduleDay.SUNDAY,
    name: '日',
  },
  {
    id: CompanyScheduleDay.MONDAY,
    name: '月',
  },
  {
    id: CompanyScheduleDay.TUESDAY,
    name: '火',
  },
  {
    id: CompanyScheduleDay.WEDNESDAY,
    name: '水',
  },
  {
    id: CompanyScheduleDay.THURSDAY,
    name: '木',
  },
  {
    id: CompanyScheduleDay.FRIDAY,
    name: '金',
  },
  {
    id: CompanyScheduleDay.SATURDAY,
    name: '土',
  },
];

export const formatDate = 'YYYY/MM/DD';

export const listReduceSalary = [
  {
    value: REDUCE_FEE.UNEMPLOYMENT_INSURANCE,
    label: '雇用保険料',
  },
  {
    value: REDUCE_FEE.PERSONAL_INCOME_TAX,
    label: '所得税',
  },
];

export const REGEX_ONLY_NUMBER = /^[0-9]+$/;

export const nameJPFieldsPaperUpdate = {
  name: '手配名',
  maxWorker: '人工',
  noteConstructionSite: 'プロジェクト概要・メモ',
  date: '開始日 ‐ 終了日',
  timeDesire: '希望時間',
  shift: 'プロジェクト時間',
  description: '仕事の内容',
};

export const titlesShift = {
  // morningShift: '日中',
  allDay: '日中',
  morningShift: '午前',
  afternoonShift: '午後',
  eveningShift: '夜間',
  otherShift: 'オプション',
};

export enum TIME_OFF_TABS {
  COMPANY = 'company',
  ADMIN = 'admin',
}

export enum COMPANY_TABS {
  APPROVAL_PENDING = '1',
  APPROVED = 2,
}

export enum PhaseCreatePaymentOtherWorker {
  CONFIRM_PAYMENT = 1,
  REDIRECT_CREATE_PAYMENT_REQUEST = 2,
}

export const listCompanyBusinessField = [
  {
    name: '一般の事業',
    id: CompanyBusinessField.GENERAL,
  },
  // {
  //   name: '農林水 清酒製造の事業',
  //   id: CompanyBusinessField.AFS,
  // },
  {
    name: '建設の事業',
    id: CompanyBusinessField.CONSTRUCTION,
  },
];

export const listGender = [
  {
    name: '男性',
    id: Gender.MALE,
  },
  {
    name: '女性',
    id: Gender.FEMALE,
  },
];

export const listTypePicker = [
  {
    id: TYPE_PICKER.MONTH,
    name: '月',
  },
  {
    id: TYPE_PICKER.YEAR,
    name: '年',
  },
];

export const listTypePickerRegisterShift = [
  {
    value: TYPE_PICKER.WEEK,
    label: '週',
  },
  {
    value: TYPE_PICKER.MONTH,
    label: '月',
  },
];

export const TYPES_SALARY_FORMAT_TIMES: TypeSalaryItem[] = [
  TypeSalary.TOTAL_STANDARD_WORKING_HOUR,
  TypeSalary.TOTAL_ACTUAL_WORKING_HOUR,
  TypeSalary.TOTAL_MINUTE_LATE_OR_EARLY,
  TypeSalary.TOTAL_HOUR_OT_COMMON,
  TypeSalary.TOTAL_HOUR_OT_HOLIDAY,
  TypeSalary.TOTAL_HOUR_OT_WEEKEND,
  TypeSalary.TOTAL_HOUR_OT_NIGHT_SHIFT,
];

export const listDay = {
  [DAY_OF_WEEK.SUNDAY]: '日曜日',
  [DAY_OF_WEEK.MONDAY]: '月曜日',
  [DAY_OF_WEEK.TUESDAY]: '火曜日',
  [DAY_OF_WEEK.WEDNESDAY]: '水曜日',
  [DAY_OF_WEEK.THURSDAY]: '木曜日',
  [DAY_OF_WEEK.FRIDAY]: '金曜日',
  [DAY_OF_WEEK.SATURDAY]: '土曜日',
};

export enum ErrorCode {
  Unknown_Error = 'Unknown_Error',
  Invalid_Input = 'Invalid_Input',
  Update_Error = 'Update_Error',
  Update_Expired = 'Update_Expired',
  Username_Or_Password_Invalid = 'Username_Or_Password_Invalid',
  Password_Not_True = 'Password_Not_True',
  Email_Already_exist = 'Email_Already_exist',
  Email_Not_exist = 'Email_Not_exist',
  Token_Not_Exist = 'Token_Not_Exist',
  User_Blocked = 'User_Blocked',
  User_Not_Verify = 'User_Not_Verify',
  Token_Expired = 'Token_Expired',
  /**The client not send the required token in header */
  Refresh_Token_Not_Exist = 'Refresh_Token_Not_Exist',
  /**The client send the expire token or invalid token*/
  Refresh_Token_Expire = 'Refresh_Token_Expire',
  /**The client do not have permission for this action. */
  Permission_Denied = 'Permission_Denied',
  User_Not_Exist = 'User_Not_Exist',
  Not_Found = 'Not_Found',
  Maximum_Retry_Verification_Code = 'Maximum_Retry_Verification_Code',
  Verification_Code_Invalid = 'Verification_Code_Invalid',
  Vendor_Not_Found = 'Vendor_Not_Found',
  Wrong_Company = 'Wrong_Company',
  Vendor_Not_Valid = 'Vendor_Not_Valid',
  Worker_Not_Valid = 'Worker_Not_Valid',
  This_Company_Is_Blocked = 'This_Company_Is_Blocked',
  Admin_Of_This_Company_Expire_Purchase = 'Admin_Of_This_Company_Expire_Purchase',
  Vendor_Already_Exists = 'Vendor_Already_Exists',
  Manager_Not_Valid = 'Manager_Not_Valid',
  Task_Not_Found = 'Task_Not_Found',
  Access_Denied = 'Access_Denied',
  Not_Found_The_Leader = 'Not_Found_The_Leader',
  Not_Found_The_Customer = 'Not_Found_The_Customer',
  This_Leader_Already_Have_A_Construction_Site = 'This_Leader_Already_Have_A_Construction_Site',
  You_Not_Creator_This_ConstructionSite = 'You_Not_Creator_This_ConstructionSite',
  You_Not_Vendor_This_ConstructionSite = 'You_Not_Vendor_This_ConstructionSite',
  Customer_Not_Valid = 'Customer_Not_Valid',
  Not_Found_Time_Keeping_Request = 'Not_Found_Time_Keeping_Request',
  Worker_Not_In_This_Construction_Site = 'Worker_Not_In_This_Construction_Site',
  This_Member_Not_In_This_Construction_Site = 'This_Member_Not_In_This_Construction_Site',
  This_Member_Not_Is_Worker = 'This_Member_Not_Is_Worker',
  You_Not_Leader_Of_Construction_Site = 'You_Not_Leader_Of_Construction_Site',
  Task_Not_Exist = 'Task_Not_Exist',
  Total_Worker_Too_Large = 'Total_Worker_Too_Large',
  Company_Not_Exist = 'Company_Not_Exist',
  User_Not_In_Your_Company = 'User_Not_In_Your_Company',
  Construction_Site_Not_Exist = 'Construction_Site_Not_Exist',
  Schedule_Registered = 'Schedule_Registered',
  Date_Is_Not_Valid = 'Date_Is_Not_Valid',
  Schedule_Not_Exit = 'Schedule_Not_Exit',
  Can_Not_Time_Keeping_In_The_Future = 'Can_Not_Time_Keeping_In_The_Future',
  Only_Staff_Can_Time_Keeping_Here = 'Only_Staff_Can_Time_Keeping_Here',
  This_Day_Already_Request_Time_Keeping = 'This_Day_Already_Request_Time_Keeping',
  Schedule_Was_Approved = 'Schedule_Was_Approved',
  Only_Customer_Can_Use_This_API = 'Only_Customer_Can_Use_This_API',
  Only_Vendor_Can_Use_This_API = 'Only_Vendor_Can_Use_This_API',
  You_Not_Are_Worker_Of_This_Construction_Site = 'You_Not_Are_Worker_Of_This_Construction_Site',
  Paper_Is_Not_Exist = 'Paper_Is_Not_Exist',
  Time_Keeping_Not_Valid = 'Time_Keeping_Not_Valid',
  Missing_Company_Id_In_Header = 'Missing_Company_Id_In_Header',
  Your_Company_In_Header_Not_Valid = 'Your_Company_In_Header_Not_Valid',
  This_Company_Not_Verify = 'This_Company_Not_Verify',
  Maximum_Request = 'Maximum_Request',
  This_Account_Verified = 'This_Account_Verified',
  Link_Expired = 'Link_Expired',
  Token_Register_Not_Valid = 'Token_Register_Not_Valid',
  This_Email_Already_User_Of_Company = 'This_Email_Already_User_Of_Company',
  Not_Found_Payment_Request = 'Not_Found_Payment_Request',
  Maximum_Worker_Of_This_Vendor_In_Task = 'Maximum_Worker_Of_This_Vendor_In_Task',
  Add_Card_To_Stripe_Error = 'Add_Card_To_Stripe_Error',
  This_Company_Never_Subscription_Before = 'This_Company_Never_Subscription_Before',
  This_Company_Already_Has_Subscription = 'This_Company_Already_Has_Subscription',
  This_Company_Does_Not_Have_Any_Card = 'This_Company_Does_Not_Have_Any_Card',
  Stripe_Charge_Failed = 'Stripe_Charge_Failed',
  Charge_Failed = 'Charge_Failed',
  Admin_Of_This_Company_Has_Been_Blocked = 'Admin_Of_This_Company_Has_Been_Blocked',
  You_Are_Blocked_From_This_Company = 'You_Are_Blocked_From_This_Company',
  This_Company_Maximum_Member = 'This_Company_Maximum_Member',
  You_Need_Buy_More_Exceed_Member = 'You_Need_Buy_More_Exceed_Member',
  Can_Not_Edit_Salary_Paid = 'Can_Not_Edit_Salary_Paid',
  You_Can_Not_Invite_This_Email = 'You_Can_Not_Invite_This_Email',
  Invite_Token_Invalid = 'Invite_Token_Invalid',
  Can_Not_Remove_Vendor_From_His_Request = 'Can_Not_Remove_Vendor_From_His_Request',
  This_Paper_Not_Accept = 'This_Paper_Not_Accept',
  This_Construction_Site_Not_Coming_To_Work_Or_Working = 'This_Construction_Site_Not_Coming_To_Work_Or_Working',
  Month_Added_In_Valid = 'Month_Added_In_Valid',
  Current_Active_User_Greater_New_Max_User = 'Current_Active_User_Greater_New_Max_User',
  This_Company_Has_No_Subscription = 'This_Company_Has_No_Subscription',
  Max_Date_For_Series_Task_Is_90 = 'Max_Date_For_Series_Task_Is_90',
  Invalid_Shift_Change = 'Is_Not_All_Shifts_Work',
  You_Not_Are_Leader_Of_This_Construction_Site = 'You_Not_Are_Leader_Of_This_Construction_Site',
  This_Holiday_Already_Exist = 'This_Holiday_Already_Exist',
  Construction_Salary_Not_create_Day_Off = 'Construction_Salary_Not_create_Day_Off',
  Shift_Salary_No_Salary_Require_Shift = 'Shift_Salary_No_Salary_Require_Shift',
  Shift_Salary_No_Salary_Require_From_To = 'Shift_Salary_No_Salary_Require_From_To',
  Hour_Salary_No_Salary_Require_ModeDayOff = 'Hour_Salary_No_Salary_Require_ModeDayOff',
  Hour_Salary_No_Salary_Half_Require_From_To = 'Hour_Salary_No_Salary_Half_Require_From_To',
  Month_Salary_No_Salary_Require_ModeDayOff = 'Month_Salary_No_Salary_Require_ModeDayOff',
  Only_A_Maximum_Of_30_Days_Is_Allowed_In_A_Request_Create_Day_Off = 'Only_A_Maximum_Of_30_Days_Is_Allowed_In_A_Request_Create_Day_Off',
  Exceed_The_Number_Of_Days_Off = 'Exceed_The_Number_Of_Days_Off',
  This_Day_User_Do_Not_Register_To_Work = 'This_Day_User_Do_Not_Register_To_Work',
  Break_Time_Exceeds_Registration_Time = 'Break_Time_Exceeds_Registration_Time',
  Your_Leave_Has_Expired = 'Your_Leave_Has_Expired',
  Time_Params_Full_Salary_OverLap = 'Time_Params_Full_Salary_OverLap',
  OverLap_Time_Database = 'OverLap_Time_Database',
  Construction_Site_Has_No_Tasks = 'Construction_Site_Has_No_Tasks',
  Vendor_Worker_No_Create_Day_Off = 'Vendor_Worker_No_Create_Day_Off',
  Wrong_Time_Other = 'Wrong_Time_Other',
  Wrong_Night_Time_For_Overtime = 'Wrong_Night_Time_For_Overtime',
  Time_Overlap_Time_Keep_Ping_And_User_Day_Off = 'Time_Overlap_Time_Keep_Ping_And_User_Day_Off',
  Time_Overlap_Time_Keep_Ping_And_Holiday = 'Time_Overlap_Time_Keep_Ping_And_Holiday',
  Can_Not_Found_Any_Shift_For_This_Time = 'Can_Not_Found_Any_Shift_For_This_Time',
  This_Month_Already_Has_Snapshot = 'This_Month_Already_Has_Snapshot',
  This_Day_Closed_Salary_Can_Not_Edit = 'This_Day_Closed_Salary_Can_Not_Edit',
  File_Excel_Not_Valid_Index_Not_Valid = 'File_Excel_Not_Valid_Index_Not_Valid',
  File_Excel_Not_Valid_FromTo_Not_Valid = 'File_Excel_Not_Valid_FromTo_Not_Valid',
  Max_Create_Bonus_In_Year_Is_Three = 'Max_Create_Bonus_In_Year_Is_Three',

  /* ----------------------------------- p3 ----------------------------------- */
  Admin_Update_Task = 'Admin_Update_Task',

  Can_Not_Update_Holiday_Import = 'Can_Not_Update_Holiday_Import',
  Start_Date_Task_After_Current_Date = 'Start_Date_Task_After_Current_Date',
  Approve_Paper_fail = 'Approve_Paper_fail',
  Update_Subscription_Type_Company_Fail = 'Update_Subscription_Type_Company_Fail',
  User_Suspend = 'User_Suspend',
  Admin_Normal_Access_Denied = 'Admin_Normal_Access_Denied',

  /* ----------------------------------- p5 ----------------------------------- */
  Note_Company_Extra_Can_Not_Update_This_Api = 'Note_Company_Extra_Can_Not_Update_This_Api',
  Note_Not_Found = 'Note_Not_Found',
  Unit_Price_Certificate_Id_Not_Found = 'Unit_Price_Certificate_Id_Not_Found',
  User_Inactive_Payroll = 'User_Inactive_Payroll',
  User_Not_Working_This_Time = 'User_Not_Working_This_Time',

  /* ----------------------------------- p6 ----------------------------------- */
  Task_Deleted = 'Task_Deleted',
  The_Allowed_Number_Of_Calls_Has_been_Exceeded = 'The_Allowed_Number_Of_Calls_Has_been_Exceeded',
  Schedule_Registered_Is_Override = 'Schedule_Registered_Is_Override',
  Api_Version_Old = 'Api_Version_Old',
  Please_Set_Company_Business_Field_First = 'Please_Set_Company_Business_Field_First',
}

export const messageConfirm = {
  confirm_override_week: '選択した週は既に登録されています。変更しますか？',
  confirm_override_month: '選択した月のスケジュールは既に登録されています。変更しますか？',
  confirm_remove: 'この項目を削除しますか。',
  confirm_block: 'このアカウントをブロックしますか',
  confirm_unblock: 'このアカウントのブロックを解除しますか',
};

export const messageError = {
  overlapDate: '選ばれた日',
  requiredField: '全ての項目を入力してください。',
  timeNotBetweenTwoDate: '選択した時間帯に空けが一致しません。',
  needAtLeastOneShift: '少なくとも1つのシフトを選択してください。',
  time: {
    invalid: '入力した時間が正しくありません。',
  },
};

export const textJP = {
  block: 'ブロック',
  un_block: 'ブロック解除',
  edit: '編集',
  delete: '削除',
  add_data_other_shift: 'データシフトオプションを追加',
  shift: {
    otherShift: 'オプション',
  },
};

export const priceOptions = [
  {
    id: TypeServiceCharge.YES_VALUE,
    name: '固定',
  },
  {
    id: TypeServiceCharge.YES_PERCENT,
    name: '%',
  },
];

export const colorsPicker = [
  '#4D4D4D',
  '#999999',
  '#FFFFFF',
  '#F44E3B',
  '#FE9200',
  '#FCDC00',
  '#DBDF00',
  '#A4DD00',
  '#68CCCA',
  '#73D8FF',
  '#AEA1FF',
  '#FDA1FF',
  '#333333',
  '#808080',
  '#cccccc',
  '#D33115',
  '#E27300',
  '#FCC400',
  '#B0BC00',
  '#68BC00',
  '#16A5A5',
  '#009CE0',
  '#7B64FF',
  '#FA28FF',
  '#000000',
  '#666666',
  '#B3B3B3',
  '#9F0500',
  '#C45100',
  '#FB9E00',
  '#808900',
  '#194D33',
  '#0C797D',
  '#0062B1',
  '#653294',
  '#AB149E',
];

export const workPlaceList = [
  {
    value: WorkPlace.IN_OFFICE,
    label: '内勤',
  },
  {
    value: WorkPlace.OUT_SIDE_OFFICE,
    label: '外勤',
  },
];

export const paperType: any = {
  [PaperType.PAYMENT_REQUEST_FIXED_PRICE]: [PaperType.PAYMENT_REQUEST_FIXED_PRICE, PaperType.PAYMENT_REQUEST_LABOR],
  [PaperType.ACCEPTANCE_CONSTRUCTION_SITE_FIXED_PRICE]: [
    PaperType.ACCEPTANCE_CONSTRUCTION_SITE_FIXED_PRICE,
    PaperType.ACCEPTANCE_CONSTRUCTION_SITE_LABOR,
  ],
  [PaperType.PAYMENT_ALL]: [],
};

/**
       Validate khi sửa 休憩時間: ( VD data setting của công ty: làm việc từ 5h: nghỉ 30p và làm từ 8 tiếng sẽ nghỉ 1 tiếng)
         Confirm ý hiểu cho 3 case dưới:
        + TH 1 tgian làm việc thực tế < giờ setting của công ty: VD: tgian làm việc =4h
        -> Validate khi edit 休憩時間 <= 4h
        + TH 2 tgian làm việc thực tế nằm trong 1 khoảng gần nhẩt: VD: tgian làm việc trong khoảng  5-8h
        -> Validate khi edit 休憩時間 phải <= 30p
        + TH 3 tgian làm việc thực tế > giờ setting của công ty: VD: tgian làm việc trong khoảng  >8h
        -> Validate khi edit 休憩時間 phải < 60p
       */

export enum TypeAlertBreakTime {
  TH0 = 0, // show alert // Validate durationRest > totalRawDurationByMinute
  TH1 = 1, // show alert
  TH2 = 2, // show alert
  TH3 = 3, // show alert
  // Default = 99, // no Alert
}

export const CaseShowAlertBreakTime = [
  TypeAlertBreakTime.TH0,
  TypeAlertBreakTime.TH1,
  TypeAlertBreakTime.TH2,
  TypeAlertBreakTime.TH3,
];

export const emptyData = '-';
export const monthPaymentName = 'paymentMonthOffset';
export const purchaseDateName = 'purchaseDate';
export const workPlaceName = 'workPlace';
export const dateWorkPlaceName = 'dateWorkPlace';

export const shiftName = {
  [SHIFT.MORNING]: 'morningShift',
  [SHIFT.AFTERNOON]: 'afternoonShift',
  [SHIFT.EVENING]: 'eveningShift',
  [SHIFT.OTHER]: 'otherShift',
};

export const timeOffFieldKeys = {
  mode: '休暇の形式',
  date: '日付',
  hour: '有休時間',
  from: '開始時間',
  to: '終了時間',
  approveByName: '承認者',
  approveAt: '承認日',
  type: 'タイプ',
  note: 'メモ',
  shift: 'シフト',
};

export const labelShits = {
  [SHIFT.MORNING]: '午前',
  [SHIFT.AFTERNOON]: '午後',
  [SHIFT.EVENING]: '夜間',
  [SHIFT.OTHER]: 'オプション',
};

export const Z_INDEX = {
  TASK_DAY: {
    DEFAULT: 1,
    ON_DRAG: 1071,
    ON_OPEN: 1069,
  },
  TOOLTIP_DAY: {
    DEFAULT: 100,
    ON_DRAG: 1070,
  },
} as const;
